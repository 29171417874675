import Swal from "sweetalert2";
export const Global = {
  // #region Urls
  appBaseUrl: "https://api.ugbadmission.in/api/", //need to open for prod
 imageUrl: "https://api.ugbadmission.in/docs/", // need to open for prod
  //appBaseUrl: "http://103.181.109.178:8085/api/",
  //imageUrl: "http://103.181.109.178:8085/docs/",
  //appBaseUrl: "http://localhost:61312/api/",
  //imageUrl: "http://localhost:61312/docs/",

  // #endregion

  getBaseUrl() {
    return this.appBaseUrl;
  },
  // #endregion

  // #region Error alert
  showErrorAlert(isToast, icon, text) {
    let content = "<strong><font color='white'>" + text + "</font></strong>";
    Swal.fire({
      toast: isToast,
      position: "top-end",
      icon: icon,
      html: content,
      iconColor: "white",
      showConfirmButton: false,
      timer: 3500,
      background: "red",
    });
  },
  // #endregion

  // #region Success alert
  showSuccessAlert(isToast, icon, text) {
    var content = "<strong><font color='white'>" + text + "</font></strong>";
    Swal.fire({
      toast: isToast,
      position: "top-end",
      icon: icon,
      html: content,
      iconColor: "white",
      showConfirmButton: false,
      timer: 3500,
      background: "green",
    });
  },
  // #endregion

  // #region Confirmation alert
  async showConfirmationAlert(title, text, icon) {
    return Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    });
  },
  //#endregion
  //#region  generate random passwotd
  generatePassword() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  },
  //#endregion

  // #region All Keys
  tokenKey: "tokenKey",
  userName: "userName",
  firstName: "firstName",
  lastName: "lastName",
  fullName: "fullName",
  profileImage: "profileImage",
  userId: "userId",
  companyNamePrefix: "@",
  companyNamePostfix: "2018, All rights reserved.",
  companyName: "University Of Gour Banga ",
  address: "University Of Gour Banga, P.O-Mokdumpur, Dist-Malda, West Bengal",
  email: "E-mail: ugb.admissionenquiry@gmail.com",
  phone: "Help Desk : 9123077237 & 8335949596  (From 08:00 AM To 10:00 PM)  ",
  poweredBy:
    "University of Gour Banga | Copyright © 2018 | All rights reserved.",
  // #endregion
};
