import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    dark: true,
    themes: [
      {
        themeData: {
          Id: 2,
          name: "Blue",
          dark: {
            primary: "#ff3d71",
            accent: "#e83e8c",
            secondary: "#425761",
            success: "#00d68f",
            info: "#0095ff",
            warning: "#ffaa00",
            error: "#ff3d71",
            background: "#151a30",
            cardbackground: "#222b45",
            appbar: "#222B45",
            appbarcontent: "#fff",
            sidepanel: "#222B45",
            primaryBtn: "#598bff",
            secondaryBtn: "#607d8b",
            textBtn: "#fff",
          },
          light: {
            primary: "#3366FF",
            accent: "#e83e8c",
            secondary: "#33393e",
            success: "#00d68f",
            info: "#0095ff",
            warning: "#ffaa00",
            error: "#ff3d71",
            background: "#edf1f7",
            cardbackground: "#FFFFFF",
            appbar: "#fff",
            appbarcontent: "#212121",
            sidepanel: "#FFFFFF",
            primaryBtn: "#598bff",
            secondaryBtn: "#6c757d",
            textBtn: "#fff",
          },
        },
      },

      {
        themeData: {
          Id: 6,
          name: "Orange",
          dark: {
            primary: "#ffa450",
            accent: "#FFCA28",
            secondary: "#425761",
            success: "#00d68f",
            info: "#0095FF",
            warning: "#FFAA00",
            error: "#ff3d71",
            background: "#161B30",
            cardbackground: "#222B45",
            appbar: "#222B45",
            appbarcontent: "#fff",
            sidepanel: "#222B45",
            primaryBtn: "#ffc94d",
            secondaryBtn: "#607d8b",
            textBtn: "#fff",
          },
          light: {
            primary: "#ffa450",
            accent: "#a1e754",
            secondary: "#33393e",
            success: "#00d68f",
            info: "#0095FF",
            warning: "#FFAA00",
            error: "#ff3d71",
            background: "#EBEFF5",
            cardbackground: "#FFFFFF",
            appbar: "#6200EE",
            appbarcontent: "#fff",
            sidepanel: "#D3D3D3",
            primaryBtn: "#ffc94d",
            secondaryBtn: "#6c757d",
            textBtn: "#fff",
          },
        },
      },

      {
        themeData: {
          Id: 1,
          name: "Purple",
          dark: {
            primary: "#6200EE",
            accent: "#FFCA28",
            secondary: "#425761",
            success: "#00d68f",
            info: "#0095ff",
            warning: "#ffaa00",
            error: "#ff3d71",
            background: "#161B30",
            cardbackground: "#222B45",
            appbar: "#222B45",
            appbarcontent: "#fff",
            sidepanel: "#222B45",
            primaryBtn: "#ff7b9e",
            secondaryBtn: "#607d8b",
            textBtn: "#fff",
          },
          light: {
            primary: "#642BB8",
            accent: "#ffe063",
            secondary: "#33393e",
            success: "#00d68f",
            info: "#0095ff",
            warning: "#ffaa00",
            error: "#ff3d71",
            background: "#EDF1F7",
            cardbackground: "#fff",
            appbar: "#642BB8",
            appbarcontent: "#fff",
            sidepanel: "#fff",
            primaryBtn: "#903df4",
            secondaryBtn: "#6c757d",
            textBtn: "#fff",
          },
        },
      },
    ],
  },
});
