import { globalMixin } from "../../mixins/globalMixin";
import { ApiEndPoint } from "../../helpers/apiEndPoint";
import { ApiService } from "@/helpers/apiService";
import { Global } from "@/helpers/global";

export const login = {
  // #region Mixin
  mixins: [globalMixin],
  // #endregion

  // #region Components
  components: {},
  // #endregion

  // #region Data Section
  data() {
    return {
      mobile: "",
      password: "",
      isLoaderActive: false,
      isholdingFormValid: true,
      isPasswordVisible: false,
      companyName: Global.companyName,

      address: Global.address,
      email: Global.email,
      phone: Global.phone,

      loginLogo: "https://api.ugbadmission.in/docs/logo.png",
      
      addDialog: false,
      isDialogLoaderActive: false,
      isFormAddValid: false,
      Fmobile: "",
      email: "",
    };
  },
  // #endregion
  //#region - Function Created
  created() {
    // get the vuetify dark theme false
    this.$vuetify.theme.dark = false;
  },
  //#endregion
  //#region Mountedj
  //#endregion

  // #region Method Section
  methods: {
    gotoToNotification() {
      window.open(
        "https://ugbadmission.in/pdf/UGB_pg_admission_notification.pdf",
        "_blank"
      );
    },
    gotoToEWS() {
      window.open("https://ugbadmission.in/pdf/EWS.pdf", "_blank");
    },
    gotoToKeyDates() {
      window.open(
        "https://ugbadmission.in/pdf/UGB_key_dates_admission.pdf",
        "_blank"
      );
    },
    gotoToFeesStructure() {
      window.open(
        "https://ugbadmission.in/pdf/UGB_fees_structute_admission.pdf",
        "_blank"
      );
    },
    gotoToSeatMatrix() {
      window.open("https://ugbadmission.in/pdf/UGB_SEAT_MATRIX.pdf", "_blank");
    },
    gotoToCourseOffered() {
      window.open(
        "https://ugbadmission.in/pdf/UGB_COURSES_OFFERED.pdf",
        "_blank"
      );
    },
    gotoToInstruction() {
      window.open("https://ugbadmission.in/pdf/UGB_INSTRUCTIONS.pdf", "_blank");
    },
    gotoToPML() {
      window.open("https://ugbadmission.in/ugb_display/index.html", "_blank");
    },

    gotoToOFOC() {
      window.open("https://ugbadmission.in/ugb_display/OFOC.html", "_blank");
    },

    gotoToAdmission() {
      window.open(
        "https://ugbadmission.in/ugb_display/Admission.html",
        "_blank"
      );
    },

    gotoToAbcId() {
      window.open("https://www.abc.gov.in/", "_blank");
    },

    openLink() {},
    newApplicant() {
      this.$router.push({ name: "NewApplicant" });
    },
    forgotPassword() {
      if (this.$refs.holdingFormAdd.validate()) {
        this.isDialogLoaderActive = true;

        ApiService.post(ApiEndPoint.Authentication.forgotPassword, {
          Email: this.email,
          Mobile: this.Fmobile,
        })
          .then((response) => {
            console.log(response);
            this.isDialogLoaderActive = false;
            if (response.data.status.isSuccess == "true") {
              if (response.data.Data == "3") {
                Global.showErrorAlert(true, "error", "Email/Mobile is wrong");
              }
              if (response.data.Data == "2") {
                Global.showSuccessAlert(
                  true,
                  "success",
                  "Check your SMS/Email for password details"
                );
                this.addDialog = false;
              }
              if (response.data.Data == "1") {
                Global.showErrorAlert(
                  true,
                  "error",
                  "You have reached max limit of forgot password"
                );
              }
            }
          })
          .catch((error) => {
            this.isDialogLoaderActive = false;
            Global.showErrorAlert(true, "error", "Something went wrong");
          });
      }
    },
    // #region Validate Login
    validateLogin() {
      if (this.$refs.holdingForm.validate()) {
        this.isLoaderActive = true;

        ApiService.post(ApiEndPoint.Authentication.login, {
          UserName: this.mobile,
          Password: this.password,
        })
          .then((response) => {
            // console.log("lll", response);

            this.isLoaderActive = false;
            if (response.data.status.isSuccess == "true") {
              console.log("Loggedin", response.data.Data);
              // secureLS.set(Global.tokenKey, response.data.token);
              secureLS.set(Global.userId, response.data.Data.UserId);
              secureLS.set(Global.firstName, response.data.Data.FName);
              secureLS.set(Global.lastName, response.data.Data.LName);
              secureLS.set(Global.fullName, response.data.Data.FName);
              secureLS.set(Global.userName, response.data.Data.UserName);

              // response.data.Data.FName + " " + response.data.Data.LName
              // secureLS.set(
              //   Global.profileImage,
              //   response.data.userData.user_profile_image
              // );

              // secureLS.set(Global.userId, response.data.userData.user_id);
              this.$router.push({ name: "NewApplication" });
            } else if (response.data.status.isSuccess == "false") {
              Global.showErrorAlert(
                true,
                "error",
                response.data.status.message
              );
            }
          })
          .catch((error) => {
            this.isLoaderActive = false;
            Global.showErrorAlert(true, "error", "Something went wrong");
          });
      }
    },
    // #endregion
  },
  //#endregion
};
