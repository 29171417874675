import { Global } from "@/helpers/global";
import { globalMixin } from "../../../mixins/globalMixin";
import { ApiService } from "@/helpers/apiService";
import { ApiEndPoint } from "../../../helpers/apiEndPoint";
export const admissionCancellation = {
  props: ["userPermissionDataProps"],
  mixins: [globalMixin],
  data() {
    return {
      //New Props start
      entity: "Admission Cancellation",
      //New Props End
      UserId: secureLS.get(Global.userId),
      isholdingFormValid: true,
      isLoaderActive: false,
      Name: null,
      Subject: null,
      SubjectItems: [],
      AdhaarNO: null,
      FetchedAadhaarNO: null,
      EmailID: null,
      MobileNumber: null,
      AdmissionDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      menu: false,
      FeePaid: null,
      ReasonOfCancellation: null,
      BankName: null,
      BranchName: null,
      NameOfAccountHolder: null,
      AccountType: null,
      AccountItems: ["Current", "Savings"],
      AccountNumber: null,
      IFSCCode: null,
      isApplied: false,
    };
  },
  created() {
    this.UserId = secureLS.get(Global.userId);
    this.getCandidateDetail();
    this.getAdmissionCancellationDetail();
    this.getSubject(13);
  },
  computed: {},
  watch: {},
  methods: {
    getSubject(typeId) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.GetMaster, {
        typeId: typeId,
      })
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            this.SubjectItems = response.data.Data;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    getCandidateDetail() {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.getCandidateDetail, {
        userId: secureLS.get(Global.userId),
      })
        .then((response) => {
          this.isLoaderActive = false;
          if (response.data.status.isSuccess == "true") {
            if (response.data.Data.personalDetail != null) {
              this.userPersonalItem = response.data.Data.personalDetail;
              this.FetchedAadhaarNO = this.userPersonalItem.AdhaarNO;
            }
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    getAdmissionCancellationDetail() {
      this.isLoaderActive = true;
      ApiService.get(
        ApiEndPoint.AdmissionCancellation.getAdmissionCancellation,
        {
          userId: secureLS.get(Global.userId),
        }
      )
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            this.isApplied = true;
            if (response.data.Data == "true") {
            } else if (response.data.Data == "false") {
              this.isApplied = false;
            }
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },
    submitAdmissionCancellation() {
      if (this.$refs.holdingForm.validate()) {
        if (this.AdhaarNO != this.FetchedAadhaarNO) {
          Global.showErrorAlert(
            true,
            "error",
            "Your Aadhaar Number is incorrect"
          );
          return;
        }
        this.isLoaderActive = true;
        var postData = {
          UserId: this.UserId,
          Name: this.Name,
          Subject: this.Subject,
          AdhaarNO: this.AdhaarNO,
          EmailID: this.EmailID,
          MobileNumber: this.MobileNumber,
          AdmissionDate: this.AdmissionDate,
          FeePaid: this.FeePaid,
          ReasonOfCancellation: this.ReasonOfCancellation,
          BankName: this.BankName,
          BranchName: this.BranchName,
          NameOfAccountHolder: this.NameOfAccountHolder,
          AccountType: this.AccountType,
          AccountNumber: this.AccountNumber,
          IFSCCode: this.IFSCCode,
        };

        ApiService.post(
          ApiEndPoint.AdmissionCancellation.postAdmissionCancellation,
          postData
        )
          .then((response) => {
            this.isLoaderActive = false;

            if (response.data.status.isSuccess == "true") {
              Global.showSuccessAlert(
                true,
                "success",
                "Your application has been submitted"
              );
              this.isApplied = true;
            } else if (response.data.result == "error") {
              Global.showErrorAlert(true, "error", response.data.message);
            }
          })
          .catch((error) => {
            this.isLoaderActive = false;
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      }
    },
  },
};
