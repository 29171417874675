<template>
  <v-app id="inspire">
    <div></div>
    <v-overlay :value="isLoaderActive" color="primary">
      <v-progress-circular
        indeterminate
        size="50"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-main>
      <v-container
        class="fill-height"
        fluid
        :style="{
          'background-color': '#E6E6E6',
        }"
      >
        <v-row align="center" justify="center">
          <v-card color="white" width="100%" height="130">
            <v-row align="center" justify="center">
              <v-col cols="12" sm="12" md="1">
                <v-img
                  :src="loginLogo"
                  height="100"
                  width="100"
                  class="mx-2 my-2"
                ></v-img>
              </v-col>
              <v-col cols="12" sm="12" md="10" class="p-0 m-0">
                <h1 style="color: #642bb8">University of Gour Banga</h1>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" md="5">
            <v-card class="mx-auto" color="white">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title> New Candidate - Enrollment </v-toolbar-title>

                <v-spacer></v-spacer>
                <div>(*) Indicates Mandatory Fields</div>
              </v-toolbar>

              <v-card-text>
                <v-form
                  ref="holdingForm"
                  v-model="isholdingFormValid"
                  lazy-validation
                >
                  <v-text-field
                    label="Enter 10th/Secondary Examination Roll No"
                    prepend-inner-icon="mdi-numeric"
                    regular
                    clearable
                    type="text"
                    color="primary"
                    v-model="item.rollNo"
                    :rules="validationRulesRequired"
                  ></v-text-field>

                  <v-text-field
                    label="First Name of the Applicant"
                    prepend-inner-icon="mdi-alphabetical"
                    regular
                    clearable
                    type="text"
                    color="primary"
                    v-model="item.firstName"
                    :rules="validationRules_max_25"
                    maxlength="25"
                    @keypress="acceptCharacterWithSpace"
                    counter="25"
                  >
                  </v-text-field>
                  <v-text-field
                    label="Middle Name of the Applicant"
                    prepend-inner-icon="mdi-alphabetical"
                    regular
                    clearable
                    type="text"
                    color="primary"
                    :rules="validationRules_max_25_without_required"
                    v-model="item.middleName"
                    maxlength="25"
                    @keypress="acceptCharacterWithSpace"
                    counter="25"
                  ></v-text-field>
                  <v-text-field
                    label="Last Name of the Applicant"
                    prepend-inner-icon="mdi-alphabetical"
                    regular
                    clearable
                    type="text"
                    color="primary"
                    v-model="item.lastName"
                    :rules="validationRules_max_25"
                    maxlength="25"
                    @keypress="acceptCharacterWithSpace"
                    counter="25"
                  >
                  </v-text-field>
                  <v-text-field
                    label="Email ID"
                    prepend-inner-icon="mdi-email"
                    regular
                    clearable
                    type="text"
                    color="primary"
                    v-model="item.emailId"
                    :rules="validationRules_email"
                    maxlength="100"
                    counter="100"
                  ></v-text-field>
                  <v-text-field
                    label="Re-Enter Email ID"
                    prepend-inner-icon="mdi-email"
                    regular
                    clearable
                    type="text"
                    color="primary"
                    v-model="item.emailIdReEnter"
                    :rules="validationRules_email"
                    maxlength="100"
                    counter="100"
                  ></v-text-field>
                  <v-text-field
                    label="Mobile Number"
                    prepend-inner-icon="mdi-cellphone"
                    regular
                    clearable
                    type="text"
                    color="primary"
                    v-model="item.mobile"
                    :rules="validationRulesMobile"
                    @keypress="acceptDigit"
                    maxlength="10"
                    counter="10"
                  >
                  </v-text-field>

                  <v-text-field
                    label="Re-Enter Mobile Number"
                    prepend-inner-icon="mdi-numeric"
                    regular
                    clearable
                    type="text"
                    color="primary"
                    v-model="item.remobile"
                    :rules="validationRulesMobile"
                    @keypress="acceptDigit"
                    maxlength="10"
                    counter="10"
                  >
                  </v-text-field>

                  <div class="mt-2">
                    <h3 style="color: #642bb8">Login Password</h3>
                  </div>
                  <div class="mt-2 mb-4">
                    <h5 style="color: red">
                      Your password must be 6-13 characters length and contain
                      at least one lower and upper case letter, one number, and
                      one special character (Like #, $, @, !, %, &, *)
                    </h5>
                  </div>

                  <v-text-field
                    label="Password"
                    prepend-inner-icon="mdi-lock"
                    regular
                    clearable
                    color="primary"
                    v-model="item.password"
                    :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    @click:append="isPasswordVisible = !isPasswordVisible"
                    @keyup.enter="validateLogin"
                    :rules="validationRulesRequired"
                  ></v-text-field>
                  <v-text-field
                    label="Confirm Password"
                    prepend-inner-icon="mdi-lock"
                    regular
                    clearable
                    color="primary"
                    v-model="item.confirmPassword"
                    :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    @click:append="isPasswordVisible = !isPasswordVisible"
                    @keyup.enter="validateLogin"
                    :rules="validationRulesRequired"
                  ></v-text-field>

                  <v-checkbox
                    v-model="item.terms"
                    label="I agree the Terms of Service and authorize text messages to my mobile number above."
                    color="purple"
                    hide-details
                    :rules="validationRulesRequired"
                  ></v-checkbox>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn text small color="primary" @click="newApplicant">
                  Already Applied? Go Back to Login</v-btn
                >
                <v-divider></v-divider>

                <div class="text-center">
                  <v-btn
                    class="ma-2"
                    filled
                    color="primary"
                    :disabled="!isholdingFormValid"
                    @click="validation"
                  >
                    Apply Now</v-btn
                  >
                </div>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-footer color="primary" padless>
      <v-row justify="center" no-gutters>
        <v-col class="primary py-4 text-center white--text" cols="12">
          <!-- {{ new Date().getFullYear() }} —  -->

          <strong>{{ companyName }}</strong
          ><br />
          {{ address }}<br />
          <small class="mr-4">{{ email }}</small> -
          <small class="ml-4"> {{ phone }}</small>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { newApplicant } from "../newApplicant/newApplicant";
export default newApplicant;
</script>
