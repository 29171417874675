<template>
  <!-- Card Start -->
  <v-container fluid class="pa-4">
    <v-overlay :value="isLoaderActive" color="primary">
      <v-progress-circular
        indeterminate
        size="50"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-card class="mb-10">
      <v-row class="ml-4 mr-4 mt-1 mb-4" align="center">
        <v-toolbar-title dark color="primary">
          <v-list-item two-line>
            <v-list-item-content>
              <v-avatar class="text-center" size="100">
                <img src="../../../assets/images/logoi.png" alt="" />
              </v-avatar>
              <v-list-item-title class="text-h5 text-center">
                <strong class="text-center">UNIVERSITY OF GOUR BANGA</strong>
              </v-list-item-title>
              <v-list-item-subtitle class="text-center"
                >ONLINE FORM OF COUNSELLING (OFOC)</v-list-item-subtitle
              >
              <v-list-item-subtitle class="text-center"
                >POST GRADUATE ADMISSION (PROVISIONAL) FOR THE ACADEMIC SESSION
                2024-2025</v-list-item-subtitle
              >
              <v-list-item-subtitle class="text-center">
                <!--<strong>( {{ std_category_1 }} / {{ std_category_2 }})</strong>-->
                <strong>{{
                  isHome
                    ? "HOME - UNRESERVED / RESERVED"
                    : "OPEN - UNRESERVED / RESERVED"
                }}</strong>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-toolbar-title>
      </v-row>

      <transition name="fade" mode="out-in">
        <v-form
          ref="holdingFormOfoc"
          v-model="isholdingFormValid"
          lazy-validation
        >
          <v-card class="mx-auto">
            <v-card-title>
              <span class="text-h5">Name: {{ name }}</span>
            </v-card-title>
            <v-card-subtitle>
              <span class="text-h6"
                >Application Number: {{ application_id }}</span
              >
            </v-card-subtitle>
            <hr />
            <v-card-text class="text-h6" v-if="isSubmitted == 'false'">
              <v-row dense>
                <v-col cols="12" md="3" sm="12"
                  ><small class="mt-2"
                    >Applied for (Subject):<br /><small
                      style="color: red"
                      class="m-0 p-0"
                      >(Minimum one subject must choose)</small
                    ></small
                  ></v-col
                >
                <v-col cols="12" md="3" sm="12">
                  <v-checkbox
                    v-model="checkbox1"
                    :label="subject_1"
                    v-if="subject_1 != 'None'"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="3" sm="12">
                  <v-checkbox
                    v-model="checkbox2"
                    :label="subject_2"
                    v-if="subject_2 != 'None'"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="3" sm="12">
                  <v-checkbox
                    v-model="checkbox3"
                    :label="subject_3"
                    v-if="subject_3 != 'None'"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense class="mt-4">
                <v-col cols="12" sm="12" md="3"
                  ><small
                    >Category:<br />
                    <!--<small style="color: red"
                      >(Only one must be choose)</small>-->
                  </small></v-col
                >
                <v-radio-group v-model="category_apply_1" row>
                  <span>{{ std_category_1 }}</span>
                  <!-- <v-radio
                    :label="std_category_1"
                    :value="std_category_1"
                    v-if="std_category_1 != ''"
                  ></v-radio>-->
                  <v-radio
                    :label="std_category_2"
                    :value="std_category_2"
                    v-if="std_category_2 != ''"
                  ></v-radio>
                  <v-radio
                    :label="std_category_3"
                    :value="std_category_3"
                    v-if="std_category_3 != ''"
                  ></v-radio>
                </v-radio-group>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <small
                    >By submitting this Online Form Of Counselling
                    (OFOC),</small
                  >
                </v-col>
                <v-col cols="12" v-if="isHome == true">
                  <v-checkbox
                    :rules="validationRulesRequired"
                    v-model="checkbox4"
                    :label="`I do hereby declare that I have completed my Under Graduate course from the Affiliated 
                  College under the University of Gour Banga and have not been admitted in any other University.`"
                  >
                  </v-checkbox>
                </v-col>

                <v-col cols="12" v-if="isHome == true">
                  <v-checkbox
                    :rules="validationRulesRequired"
                    v-model="checkbox5"
                    :label="`I also affirm that I have valid Registration Certificate under the University of Gour Banga and till date I have not obtained Migration Certificate from the University of Gour Banga.`"
                  >
                  </v-checkbox>
                </v-col>

                <v-col cols="12" v-if="false">
                  <v-checkbox
                    :rules="validationRulesRequired"
                    v-model="checkbox6"
                  >
                    <template v-slot:label>
                      <div>
                        `I do agree to be provisionally admitted to the Post
                        Graduate course in SELECTED SUBJECT NAME in the
                        University of Gour Banga for the session 2021-22 in
                        <strong class="primary--text">{{
                          category_apply_1
                        }}</strong>
                        seat.
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>

                <v-col cols="12" v-if="false">
                  <v-checkbox
                    :rules="validationRulesRequired"
                    v-model="checkbox7"
                  >
                    <template v-slot:label>
                      <div>
                        In case, I have submitted multiple applications form due
                        to
                        <strong style="color: black">
                          incorrect entries/other technical reason</strong
                        >for a particular subject, I do agree that I will submit
                        OFOC <strong style="color: black">only once</strong>
                        against the application which contains all corrected
                        entries under
                        <strong class="primary--text">{{
                          category_apply_1
                        }}</strong>
                        category.
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>

                <v-col cols="12">
                  <v-checkbox
                    :rules="validationRulesRequired"
                    v-model="checkbox8"
                  >
                    <template v-slot:label>
                      <div>
                        I do understand that if I am selected according to my
                        rank in the merit list, I will be automatically
                        provisionally admitted in
                        <!--  <strong class="primary--text">{{
                          category_apply_1
                        }}</strong>-->
                        <strong class="primary--text">{{
                          isHome
                            ? "HOME - UNRESERVED / RESERVED"
                            : "OPEN - UNRESERVED / RESERVED"
                        }}</strong>
                        seat. Therefore, if I am selected for provisional
                        admission, I will not be considered in the remaining
                        admission process.
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>

                <v-col cols="12">
                  <v-checkbox
                    :rules="validationRulesRequired"
                    v-model="checkbox9"
                  >
                    <template v-slot:label>
                      <div>
                        I do understand that submission of this form (OFOC) does
                        not guarantee my admission in
                        <!--<strong class="primary--text">{{
                          category_apply_1
                        }}</strong>-->
                        <strong class="primary--text">{{
                          isHome
                            ? "HOME - UNRESERVED / RESERVED"
                            : "OPEN - UNRESERVED / RESERVED"
                        }}</strong>
                        seat. In case I am not selected in
                        <!-- <strong class="primary--text">{{
                          category_apply_1
                        }}</strong>-->
                        <strong class="primary--text">{{
                          isHome
                            ? "HOME - UNRESERVED / RESERVED"
                            : "OPEN - UNRESERVED / RESERVED"
                        }}</strong>
                        seat according to my rank in the merit list, I may be
                        considered for
                        <strong class="primary--text"
                          ><span v-if="isHome == true">Home-Reserved/</span
                          >Open-Unreserved/Open-Reserved</strong
                        >
                        seat (if eligible) for the next phase of admission, if
                        any.
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>

                <v-col cols="12">
                  <v-checkbox
                    :rules="validationRulesRequired"
                    v-model="checkbox10"
                    :label="`I do understand that my admission (if selected) will remain provisional and will not be confirmed until payment of requisite fees and verification of necessary documents.`"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-list-item class="grow">
                <span v-if="isSubmitted == 'true'"
                  ><strong class="primary--text text-h6"
                    >Your OFOC form has been submitted successfully</strong
                  ></span
                >

                <v-row align="center" justify="end">
                  <v-btn
                    color="info"
                    @click="downloadOfoc"
                    :disabled="!isholdingFormValid"
                    v-if="isSubmitted == 'true'"
                    class="mx-4"
                  >
                    Download OFOC
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="updateOfoc"
                    :disabled="!isholdingFormValid"
                    v-if="isSubmitted == 'false'"
                  >
                    Submit OFOC
                  </v-btn>
                </v-row>
              </v-list-item>

                
            </v-card-actions>
<v-list-item class="grow">
                <span v-if="isSubmitted == 'error'"
                  ><strong class="primary--text text-h6"
                    >You are not eligible for OFOC</strong
                  ></span
                >

               
              </v-list-item>

            <v-card-actions>

            </v-card-actions>
          </v-card>
        </v-form>
      </transition>

      <!-- Card End -->
    </v-card>
  </v-container>
</template>
<script>
import { onlineFormCounselling } from "../onlineFormCounselling/onlineFormCounselling";
export default onlineFormCounselling;
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.9s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.th {
  padding: 0 1px;
}
</style>
