<template>
  <!-- Card Start -->
  <v-container fluid class="pa-4">
    <v-overlay :value="isLoaderActive" color="primary">
      <v-progress-circular
        indeterminate
        size="50"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-card class="mb-10">
      <v-row class="ml-4 mr-4 mt-1 mb-4">
        <v-toolbar-title dark color="primary">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5">
                <strong>{{ entity }}</strong>
              </v-list-item-title>
              <v-list-item-subtitle
                >Home <v-icon>mdi-chevron-right</v-icon> Application
                <v-icon>mdi-chevron-right</v-icon>
                {{ entity }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <span class="mt-4"><small style="color:red">Download Receipt after payment</small></span>
<v-btn color="primary" class="ma-2 white--text" @click="downloadReceipt()">
  Download Receipt
  <v-icon right dark>
  mdi-cloud-download
  </v-icon>
</v-btn>
      </v-row>
      <transition name="fade" mode="out-in">
        <v-data-table
          :headers="tableHeader"
          :items="dataTableRowNumbering"
          item-key="BuyerReference"
          :options.sync="pagination"
          class="elevation-0"
          :loading="tableDataLoading"
          :loading-text="tableLoadingDataText"
        
        >
          <template v-slot:no-data>
            <p class="font-weight-black bold title" style="color: red">
              No Payment Request Found
            </p>
          </template>


          <template v-slot:item.actions="{ item }">
            <v-btn  color="blue-grey" class="ma-2 white--text" @click="payNow(item.UserID, item.FeesStructure, item.BuyerReference)">
              Pay Now
              <v-icon right dark>
            mdi-arrow-right-thick
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </transition>
     
      <!-- Card End -->
    </v-card>
  </v-container>
</template>
<script>
import { onlinePayment } from "../onlinePayment/onlinePayment";
export default onlinePayment;
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.9s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
