import { Global } from "@/helpers/global";
import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
const router = new Router({
  routes: [
    {
      path: "/",
      redirect: {
        name: "Login",
      },
    },
    {
      path: "/login",
      name: "Login",
      component: require("../components/login/Login.vue").default,
    },
    {
      path: "/newApplicant",
      name: "NewApplicant",
      component: require("../components/newApplicant/NewApplicant.vue").default,
    },
    {
      path: "/home",
      component: require("../components/home/Home.vue").default,
      name: "Home",
      meta: {
        requiresAuth: true,
      },
      children: [
        //#region Dashboard
        {
          path: "dashboard",
          component: require("../components/dashboard/Dashboard.vue").default,
          name: "Dashboard",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "master/newApplication/new-application",
          component:
            require("../components/master/newApplication/NewApplication.vue")
              .default,
          name: "NewApplication",
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "master/onlineFormCounselling/online-form-counselling",
          component:
            require("../components/master/onlineFormCounselling/OnlineFormCounselling.vue")
              .default,
          name: "OnlineFormCounselling",
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "master/onlinePayment/online-payment",
          component:
            require("../components/master/onlinePayment/OnlinePayment.vue")
              .default,
          name: "OnlinePayment",
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "master/grievance",
          component: require("../components/master/grievance/Grievance.vue")
            .default,
          name: "Grievance",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "master/grievance-admin",
          component:
            require("../components/master/grievance/GrievanceAdmin.vue")
              .default,
          name: "GrievanceAdmin",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "master/cancellation/admission-cancel",
          component:
            require("../components/master/cancellation/AdmissionCancellation.vue")
              .default,
          name: "AdmissionCancellation",
          meta: {
            requiresAuth: true,
          },
        },

        //#endregion

        ,
      ],
    },
    {
      path: "*",
      component: require("../components/NotFound.vue").default,
    },
  ],
});

router.beforeEach((to, from, next) => {
  console.log(secureLS.get(Global.userId));
  try {
    //* Check if the route requires authentication & user not logged in
    if (
      to.matched.some((route) => route.meta.requiresAuth) &&
      secureLS.get(Global.userId) == ""
    ) {
      next({
        name: "Login",
      });
      return;
    }

    //* if logged in redirect to dashboard
    if (to.path === "/login" && secureLS.get(Global.userId) != "") {
      next({
        name: "Dashboard",
      });
      return;
    }
    next();
  } catch (err) {
    secureLS.removeAll();
    router.push({ name: "Login" });
  }
});
export default router;
