<template>
  <!-- Card Start -->
  <v-container fluid class="pa-4">
    <v-overlay :value="isLoaderActive" color="primary">
      <v-progress-circular
        indeterminate
        size="50"
        color="primary"
      ></v-progress-circular>
    </v-overlay>

    <v-card class="mb-10">
      <v-row class="ml-4 mr-4 mt-1 mb-4">
        <v-toolbar-title dark color="primary">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5">
                <strong>{{ entity }}</strong>
              </v-list-item-title>
              <v-list-item-subtitle
                >Home <v-icon>mdi-chevron-right</v-icon> Application
                <v-icon>mdi-chevron-right</v-icon>
                {{ entity }}
              </v-list-item-subtitle>
              <v-list-item-subtitle> </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <strong style="color: red" class="mt-8"
          >( *) Indicates mandatory fields</strong
        >
      </v-row>

      <transition name="fade" mode="out-in">
        <v-form ref="holdingForm" v-model="isholdingFormValid" lazy-validation>
          <v-card color="grey lighten-4" class="mb-12" height="auto">
            <v-row class="mx-2 mt-2" dense>
              <v-col cols="12" md="4" SM="12">
                <v-text-field
                  label="Name"
                  filled
                  :rules="validationRules_max_75"
                  v-model="Name"
                  maxlength="75"
                  counter="75"
                  @keypress="acceptCharacterWithSpace"
                >
                  <template #label
                    >Name<span class="red--text"
                      ><strong> *</strong></span
                    ></template
                  >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4" SM="12">
                <v-autocomplete
                  v-model="Subject"
                  :items="SubjectItems"
                  filled
                  chips
                  small-chips
                  :rules="validationRulesRequired"
                >
                  <template #label
                    >Subject<span class="red--text"
                      ><strong> *</strong></span
                    ></template
                  >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="4" SM="12">
                <v-text-field
                  label="Aadhaar Number"
                  filled
                  v-model="AdhaarNO"
                  :rules="validationRules_aadhaar"
                >
                  <template #label
                    >Aadhaar Number(XXXX XXXX XXXX)<span class="red--text"
                      ><strong> *</strong></span
                    ></template
                  >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row class="mx-2 mt-2" dense>
              <v-col cols="12" md="4" SM="12">
                <v-text-field
                  label="Email Id"
                  filled
                  :rules="validationRules_email"
                  v-model="EmailID"
                  maxlength="100"
                  counter="100"
                >
                  <template #label
                    >Email Id<span class="red--text"
                      ><strong> *</strong></span
                    ></template
                  >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4" SM="12">
                <v-text-field
                  label="Mobile Number"
                  filled
                  :rules="validationRulesRequired"
                  v-model="MobileNumber"
                  @keypress="acceptDigit"
                  maxlength="10"
                  counter="10"
                >
                  <template #label
                    >Mobile Number<span class="red--text"
                      ><strong> *</strong></span
                    ></template
                  >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4" SM="12">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="AdmissionDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      filled
                      v-model="AdmissionDate"
                      label="Admission Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template #label
                        >Admission Date<span class="red--text"
                          ><strong> *</strong></span
                        ></template
                      >
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="AdmissionDate" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(AdmissionDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row class="mx-2" dense>
              <v-col cols="12" md="4" SM="12">
                <v-text-field
                  label="Fee Paid"
                  filled
                  :rules="validationRulesRequired"
                  v-model="FeePaid"
                  @keypress="acceptDigit"
                >
                  <template #label
                    >Fee Paid<span class="red--text"
                      ><strong> *</strong></span
                    ></template
                  >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4" SM="12">
                <v-textarea
                  :rules="validationRulesRequired"
                  v-model="ReasonOfCancellation"
                  filled
                  label="Reason Of Cancellation"
                  auto-grow
                >
                  <template #label
                    >Reason Of Cancellation<span class="red--text"
                      ><strong> *</strong></span
                    ></template
                  >
                </v-textarea>
              </v-col>
            </v-row>

            <v-row class="mx-2 mt-2" dense>
              <v-col cols="12" md="4" SM="12">
                <v-text-field
                  label="Bank Name"
                  filled
                  :rules="validationRulesRequired"
                  v-model="BankName"
                  @keypress="acceptCharacterWithSpace"
                >
                  <template #label
                    >Bank Name<span class="red--text"
                      ><strong> *</strong></span
                    ></template
                  >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4" SM="12">
                <v-text-field
                  label="Branch Name"
                  filled
                  :rules="validationRulesRequired"
                  v-model="BranchName"
                  @keypress="acceptCharacterWithSpace"
                >
                  <template #label
                    >Branch Name<span class="red--text"
                      ><strong> *</strong></span
                    ></template
                  >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4" SM="12">
                <v-text-field
                  label="Name of Account holder (as per cheque book)"
                  filled
                  v-model="NameOfAccountHolder"
                  :rules="validationRulesRequired"
                  @keypress="acceptCharacterWithSpace"
                >
                  <template #label
                    >Name of Account holder (as per cheque book)<span
                      class="red--text"
                      ><strong> *</strong></span
                    ></template
                  >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row class="mx-2 mt-2" dense>
              <v-col cols="12" md="4" SM="12">
                <v-autocomplete
                  v-model="AccountType"
                  :items="AccountItems"
                  filled
                  chips
                  small-chips
                  :rules="validationRulesRequired"
                >
                  <template #label
                    >Account Type<span class="red--text"
                      ><strong> *</strong></span
                    ></template
                  >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="4" SM="12">
                <v-text-field
                  label="Account Number"
                  filled
                  :rules="validationRulesRequired"
                  v-model="AccountNumber"
                  maxlength="16"
                  counter="16"
                  @keypress="acceptDigit"
                >
                  <template #label
                    >Account Number<span class="red--text"
                      ><strong> *</strong></span
                    ></template
                  >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4" SM="12">
                <v-text-field
                  label="IFSC Code"
                  filled
                  v-model="IFSCCode"
                  :rules="validationRulesRequired"
                >
                  <template #label
                    >IFSC Code<span class="red--text"
                      ><strong> *</strong></span
                    ></template
                  >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row dense class="mx-4">
              <span class="red--text">
                * The university authority will not be responsible for a non
                refund due to wrong information submitted by the student or
                transaction failure from the end of Bank.
              </span>
            </v-row>
            <v-row dense class="mx-4">
              <span class="red--text">
                ** Refund process will be initiated during the month of January,
                2024, after the completion verification process.
              </span>
            </v-row>
            <v-row dense class="mx-4">
              <span class="red--text">
                ** An amount of Rs.1000/- will be deducted as cancellation
                charge
              </span>
            </v-row>
            <v-row align="center" justify="center" v-if="!isApplied">
              <v-btn
                class="mb-2"
                color="primary"
                @click="submitAdmissionCancellation"
                :disabled="!isholdingFormValid"
              >
                Submit
              </v-btn>
            </v-row>
          </v-card>
        </v-form>
      </transition>

      <!-- Card End -->
    </v-card>
  </v-container>
</template>
<script>
import { admissionCancellation } from "../cancellation/admissionCancellation";
export default admissionCancellation;
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.9s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.th {
  padding: 0 1px;
}
</style>
