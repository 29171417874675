import { Global } from "@/helpers/global";
import { ApiService } from "@/helpers/apiService";
import { ApiEndPoint } from "../../../helpers/apiEndPoint";
export const onlinePayment = {
  data() {
    return {
      //newly added
      isLoaderActive: false,
      isDialogLoaderActive: false,
      totalItemsInDB: 0,
      tableItems: [],
      tableDataLoading: false,

      loader: null,
      loading: false,
      loading3: false,

      // Data Table
      tableLoadingDataText: "Loading data",
      tableHeader: [
        {
          text: "#",
          value: "index",
          width: "5%",
          sortable: false,
          align: "start",
        },
        {
          text: "Application ID",
          value: "ApplicationID",
          width: "15%",
          sortable: true,
          align: "center",
        },
        {
          text: "First Name",
          value: "BuyerFirstName",
          sortable: false,
          width: "10%",
          align: "center",
        },
        {
          text: "Last Name",
          value: "BuyerLastName",
          sortable: false,
          width: "10%",
          align: "start",
        },

        {
          text: "University",
          value: "StdCategory",
          sortable: false,
          width: "10%",
          align: "center",
        },
        {
          text: "Category",
          value: "Category",
          sortable: false,
          width: "10%",
          align: "center",
        },
        {
          text: "Subect",
          value: "SubjectApply",
          sortable: false,
          width: "10%",
          align: "center",
        },
        {
          text: "Reference",
          value: "BuyerReference",
          sortable: false,
          width: "15%",
          align: "center",
        },
        {
          text: "Fee",
          value: "FeesStructure",
          sortable: false,
          width: "10%",
          align: "center",
        },

        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "10%",
          align: "center",
        },
      ],

      pagination: {},
      entity: "Online Payment",
      endPoint: "Town",

      paymentReceiptDownload: false,

      //end
      UserId: secureLS.get(Global.userId),
    };
  },

  computed: {
    // For numbering the Data Table Rows
    dataTableRowNumbering() {
      return this.tableItems.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },
  },
  watch: {
    pagination: {
      handler() {
        this.getPaymentDetails(this.UserId);
      },
      deep: true,
    },
  },
  created() {
    //this.getPaymentDetails(this.UserId);
  },
  methods: {
    downloadReceipt() {
      window.open(
        "http://103.181.109.178:8080/Download/servlet/DownloadServlet?reportPath=ugb_payment_receipt.jasper&id=" +
          secureLS.get(Global.userId) +
          "&pPattern=" +
          this.buyerReference
      );
    },
    payNow(UserID, FeesStructure, BuyerReference) {
      this.buyerReference = BuyerReference;
      FeesStructure = FeesStructure + ".00";
      this.paymentReceiptDownload = true;
      window.open(
        "https://payment.ugbadmission.in/Index/Validate/" +
          UserID +
          "?amt=" +
          FeesStructure +
          "&BuyerReference=" +
          BuyerReference
      );
    },
    getPaymentDetails(userId) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.OnlinePayment.GetPayment, {
        userId: userId,
      })
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            this.tableItems = response.data.Data;

            // this.totalItemsInDB = response.data.resultData.total;
          } else if (response.data.result == "error") {
            this.paymentReceiptDownload = true;
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.paymentReceiptDownload = true;
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },
  },
};
