import { Global } from "../../../helpers/global";
import { globalMixin } from "../../../mixins/globalMixin";
import { ApiService } from "@/helpers/apiService";
import { ApiEndPoint } from "../../../helpers/apiEndPoint";
import axios from "axios";
export const grievanceAdmin = {
  props: ["userPermissionDataProps"],
  mixins: [globalMixin],
  data() {
    return {
      //newly added
      isLoaderActive: false,
      isDialogLoaderActive: false,
      totalItemsInDB: 0,
      tableItems: [],
      tableDataLoading: false,
      //end
      // Data Table
      tableLoadingDataText: "Loading data",
      tableHeader: [
        {
          text: "#",
          value: "index",
          width: "5%",
          sortable: false,
          align: "start",
        },
        {
          text: "Name",
          value: "Name",
          width: "25%",
          sortable: false,
          align: "start",
        },
        {
          text: "Application Id",
          value: "ApplicationId",
          width: "20%",
          sortable: false,
          align: "start",
        },
        {
          text: "Date",
          value: "GrievanceDate",
          width: "15%",
          sortable: false,
          align: "start",
        },

        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "40%",
          align: "end",
        },
      ],

      pagination: {},
      entity: "Grievance",

      isFormAddValid: false,

      grievancePost: null,
      grievanceDoc: null,
      GrievanceDocName: null,
      viewGrievanceDialog: false,

      grievanceReply: null,
      grievanceId: null,
      applicationId:null,

      //end
    };
  },

  computed: {
    // For numbering the Data Table Rows
    dataTableRowNumbering() {
      return this.tableItems.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },
  },
  watch: {
   
    pagination: {
      handler() {
        this.get();
      },
      deep: true,
    },
  },
  methods: {
    // fetch skills
    get() {
      let { page, itemsPerPage } = this.pagination;

      let payload = {
        page: page,
        pageSize: itemsPerPage,
      };
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.Grievance.getGrievanceAdmin, payload)
        .then((response) => {
          this.isLoaderActive = false;
          if (response.data.status.isSuccess == "true") {
            this.tableItems = response.data.Data;
            if (response.data.Data.length > 0) {
              this.totalItemsInDB = parseInt(response.data.Data[0].TotalRows);
            }
            else
            {
               this.totalItemsInDB =0;
            }
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    // add reply
    addReply() {
      if (this.$refs.holdingFormAdd.validate()) {
        this.isDialogLoaderActive = true;
        var postData = {
          Id: this.grievanceId,
          GrievanceReply: this.grievanceReply,
          ReplyBy: secureLS.get(Global.userId),
          ReplyByName: secureLS.get(Global.fullName),
         
        };

        ApiService.post(ApiEndPoint.Grievance.updateGrievance, postData)
          .then((response) => {
            this.viewGrievanceDialog = false;
            this.isDialogLoaderActive = false;

            if (response.data.status.isSuccess == "true") {
              this.get();
              Global.showSuccessAlert(
                true,
                "success",
                "Grievance reply posted successfully"
              );
            } else if (response.data.result == "error") {
              Global.showErrorAlert(true, "error", response.data.message);
            }
          })
          .catch((error) => {
            this.viewGrievanceDialog = false;
            this.isDialogLoaderActive = false;
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      }
    },

    isDownloadVisible(grievanceDoc) {
      if (grievanceDoc == "" || grievanceDoc == null) {
        return false;
      } else {
        return true;
      }
    },
    showViewGrievanceDialog(item) {
      
      this.grievanceReply=null;
      this.grievanceId = item.Id;
      this.grievancePost = item.GrievancePost;
      this.viewGrievanceDialog = true;
      this.applicationId=item.ApplicationId;
    },

    download(item) {
      this.isLoaderActive = true;
      ApiService.download("download/", {
        filename: item.GrievanceDoc,
      })
        .then((response) => {
          this.isLoaderActive = false;
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", item.GrievanceDoc);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },
  },
};
