import { globalMixin } from "../../mixins/globalMixin";
import { Global } from "../../helpers/global";
import { ApiService } from "@/helpers/apiService";
import { ApiEndPoint } from "../../helpers/apiEndPoint";
export const home = {
  mixins: [globalMixin],
  //#region - Data Section
  data() {
    return {
      //#region - Menu
      menuClass: Global.menuClass,
      menuColor: Global.menuColor,
      menuItemsUsers: [
        {
          text: "New Application",
          icon: "mdi-city",
          to: "/home/master/newApplication/new-application",
        },
        {
          text: "Online Payment",
          icon: "mdi-city",
          to: "/home/master/onlinePayment/online-payment",
        },
      ],
      menuItemsOthers: [
        {
          text: "Notification",
          icon: "mdi-city",
          to: "http://www.google.com",
        },
        {
          text: "Key Dates",
          icon: "mdi-city",
          to: "/home/master/newApplication/new-application",
        },
        {
          text: "Fees Structure",
          icon: "mdi-city",
          to: "/home/master/newApplication/new-application",
        },
        {
          text: "Seat Matrix",
          icon: "mdi-city",
          to: "/home/master/newApplication/new-application",
        },
        {
          text: "Course Offered",
          icon: "mdi-city",
          to: "/home/master/newApplication/new-application",
        },
        {
          text: "Instruction",
          icon: "mdi-city",
          to: "/home/master/newApplication/new-application",
        },
      ],

      //#endregion
      candidateValid: false,
      //#region - Side Bar Data
      sideMenu: true,
      toggleMini: false,
      toggleNavbar: true,
      companyName: Global.companyName,
      poweredBy: Global.poweredBy,
      //#endregion

      //#region - Logged User Data
      loggedUserFullName: secureLS.get(Global.fullName).toUpperCase(),
      userName: secureLS.get(Global.userName),
      loggedUserInitials:
        secureLS.get(Global.firstName).substring(0, 1).toUpperCase() +
        secureLS.get(Global.lastName).substring(0, 1).toUpperCase(),
      loggedUserProfileImage:
        secureLS.get(Global.profileImage) == "0"
          ? Global.profileImageUrl + "default.png"
          : Global.profileImageUrl + secureLS.get(Global.profileImage),

      //#endregion

      //#region - Theme Data
      isLoaderActive: false,
      isDarkMode: false,
      themeId: "",
      themeName: "",
      themes: [
        {
          themeData: {
            Id: 2,
            name: "Blue",
            dark: {
              primary: "#ff3d71",
              accent: "#e83e8c",
              secondary: "#425761",
              success: "#00d68f",
              info: "#0095ff",
              warning: "#ffaa00",
              error: "#ff3d71",
              background: "#151a30",
              cardbackground: "#222b45",
              appbar: "#222B45",
              appbarcontent: "#fff",
              sidepanel: "#222B45",
              primaryBtn: "#598bff",
              secondaryBtn: "#607d8b",
              textBtn: "#fff",
            },
            light: {
              primary: "#3366FF",
              accent: "#e83e8c",
              secondary: "#33393e",
              success: "#00d68f",
              info: "#0095ff",
              warning: "#ffaa00",
              error: "#ff3d71",
              background: "#edf1f7",
              cardbackground: "#FFFFFF",
              appbar: "#fff",
              appbarcontent: "#212121",
              sidepanel: "#FFFFFF",
              primaryBtn: "#598bff",
              secondaryBtn: "#6c757d",
              textBtn: "#fff",
            },
          },
        },
        {
          themeData: {
            Id: 6,
            name: "Orange",
            dark: {
              primary: "#ffa450",
              accent: "#FFCA28",
              secondary: "#425761",
              success: "#00d68f",
              info: "#0095FF",
              warning: "#FFAA00",
              error: "#ff3d71",
              background: "#161B30",
              cardbackground: "#222B45",
              appbar: "#222B45",
              appbarcontent: "#fff",
              sidepanel: "#222B45",
              primaryBtn: "#ffc94d",
              secondaryBtn: "#607d8b",
              textBtn: "#fff",
            },
            light: {
              primary: "#ffa450",
              accent: "#a1e754",
              secondary: "#33393e",
              success: "#00d68f",
              info: "#0095FF",
              warning: "#FFAA00",
              error: "#ff3d71",
              background: "#EBEFF5",
              cardbackground: "#FFFFFF",
              appbar: "#6200EE",
              appbarcontent: "#fff",
              sidepanel: "#D3D3D3",
              primaryBtn: "#ffc94d",
              secondaryBtn: "#6c757d",
              textBtn: "#fff",
            },
          },
        },

        {
          themeData: {
            Id: 1,
            name: "Purple",
            dark: {
              primary: "#6200EE",
              accent: "#FFCA28",
              secondary: "#425761",
              success: "#00d68f",
              info: "#0095ff",
              warning: "#ffaa00",
              error: "#ff4c51",
              background: "#161B30",
              cardbackground: "#222B45",
              appbar: "#222B45",
              appbarcontent: "#fff",
              sidepanel: "#222B45",
              primaryBtn: "#ff7b9e",
              secondaryBtn: "#607d8b",
              textBtn: "#fff",
            },
            light: {
              primary: "#642BB8",
              accent: "#ffe063",
              secondary: "#33393e",
              success: "#00d68f",
              info: "#0095ff",
              warning: "#ffaa00",
              error: "#ff4c51",
              background: "#EDF1F7",
              cardbackground: "#fff",
              appbar: "#642BB8",
              appbarcontent: "#fff",
              sidepanel: "#fff",
              primaryBtn: "#903df4",
              secondaryBtn: "#6c757d",
              textBtn: "#fff",
            },
          },
        },
      ],
      //#endregion
    };
  },
  //#endregion
  //#region - Mounted Section
  mounted() {
    window.onpopstate = (event) => {
      if (this.$router.history.current.path == "/home") {
        this.$router.push({
          path: "home/master/role/role-master",
        });
      }
    };
  },
  //#endregion

  //#region - Computed Section
  computed: {
    mini() {
      return this.toggleMini;
    },
  },
  //#endregion
  //#region - Created Section
  created() {
    this.getOFOC(secureLS.get(Global.userId));
    this.getAdmissionCancellationDetail();
    this.setTheme("Purple");
    if (secureLS.get(Global.userName) == "admin") {
      this.menuItemsUsers.push({
        text: "Grievance Admin",
        icon: "mdi-city",
        to: "/home/master/grievance-admin",
      });
    } else {
      this.menuItemsUsers.push({
        text: "Grievance",
        icon: "mdi-city",
        to: "/home/master/grievance",
      });
    }
    // this.getLoggedUserRolePermission();
  },
  //#endregion
  //#region - Method Section
  methods: {
    gotoToNotification() {
      window.open(
        "https://ugbadmission.in/pdf/UGB_pg_admission_notification.pdf",
        "_blank"
      );
    },
    gotoToEWS() {
      window.open("https://ugbadmission.in/pdf/EWS.pdf", "_blank");
    },
    gotoToKeyDates() {
      window.open(
        "https://ugbadmission.in/pdf/UGB_key_dates_admission.pdf",
        "_blank"
      );
    },
    gotoToFeesStructure() {
      window.open(
        "https://ugbadmission.in/pdf/UGB_fees_structute_admission.pdf",
        "_blank"
      );
    },
    gotoToSeatMatrix() {
      window.open("https://ugbadmission.in/pdf/UGB_SEAT_MATRIX.pdf", "_blank");
    },
    gotoToCourseOffered() {
      window.open(
        "https://ugbadmission.in/pdf/UGB_COURSES_OFFERED.pdf",
        "_blank"
      );
    },
    gotoToInstruction() {
      window.open("https://ugbadmission.in/pdf/UGB_INSTRUCTIONS.pdf", "_blank");
    },

    //#region - Toggle Mini Bar
    toggleMiniBar() {
      if (!this.$vuetify.breakpoint.lgAndUp) {
        this.sideMenu = !this.sideMenu;
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        this.toggleMini = !this.toggleMini;
      }
    },
    //#endregion

    //#region -Logout
    logout() {
      secureLS.removeAll();
      this.$router.push({ name: "Login" });
    },
    //#endregion

    //#region - Toggle Theme
    toggleLightDarkMode() {
      this.$vuetify.theme.dark = this.isDarkMode;
    },
    //#endregion

    //#region - Set Theme
    setTheme(themeName) {
      this.$vuetify.theme.dark = this.isDarkMode;
      Object.keys(this.themes).forEach((i) => {
        if (
          this.themes[i].themeData.name.toUpperCase() == themeName.toUpperCase()
        ) {
          let dark = this.themes[i].themeData.dark;
          let light = this.themes[i].themeData.light;
          this.themeId = this.themes[i].themeData.Id;
          Object.keys(dark).forEach((j) => {
            this.$vuetify.theme.themes.dark[j] = dark[j];
          });
          Object.keys(light).forEach((j) => {
            this.$vuetify.theme.themes.light[j] = light[j];
          });
        }
      });
    },
    //#endregion

    getOFOC(userId) {
      ApiService.get(ApiEndPoint.OFOC.GetOfoc, {
        userId: userId,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status.isSuccess == "true") {
            this.candidateValid = true;
            this.menuItemsUsers.push({
              text: "OFOC",
              icon: "mdi-city",
              to: "/home/master/OnlineFormCounselling/online-form-counselling",
            });
          } else if (response.data.result == "error") {
          } else {
            this.candidateValid = false;
            this.menuItemsUsers.push({
              text: "OFOC",
              icon: "mdi-city",
              to: "/home/master/OnlineFormCounselling/online-form-counselling",
            });
          }
        })
        .catch((error) => {});
    },

    getAdmissionCancellationDetail() {
      this.isLoaderActive = true;
      ApiService.get(
        ApiEndPoint.AdmissionCancellation.getAdmissionCancellation,
        {
          userId: secureLS.get(Global.userId),
        }
      )
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            if (response.data.Data == "true") {
            } else if (response.data.Data == "false") {
              // need to enable later
              // this.menuItemsUsers.push({
              //   text: "Admission Cancellation",
              //   icon: "mdi-city",
              //   to: "/home/master/cancellation/admission-cancel",
              // });
            }
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },
  },
  //#endregion
};
