<template>
  <!-- Card Start -->
  <v-container fluid class="pa-4">
    <v-overlay :value="isLoaderActive" color="primary">
      <v-progress-circular
        indeterminate
        size="50"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-card class="mb-10">
      <v-row class="ml-4 mr-4 mt-1 mb-4">
        <v-toolbar-title dark color="primary">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5">
                <strong>{{ entity }}</strong>
              </v-list-item-title>
              <v-list-item-subtitle
                >Home <v-icon>mdi-chevron-right</v-icon> Application
                <v-icon>mdi-chevron-right</v-icon>
                {{ entity }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-row>
      <transition name="fade" mode="out-in">
        <v-data-table
          :headers="tableHeader"
          :items="dataTableRowNumbering"
          item-key="id"
          :options.sync="pagination"
          class="elevation-0"
          :loading="tableDataLoading"
          :loading-text="tableLoadingDataText"
          :server-items-length="totalItemsInDB"
          :items-per-page="15"
          :footer-props="{
            itemsPerPageOptions: [15, 20, 25],
          }"
        >
          <template v-slot:no-data>
            <p class="font-weight-black bold title" style="color: red">
              No Data Found
            </p>
          </template>

          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <span class="text-right mx-1 d-none d-md-block">
                <strong class="primary--text">
                  {{ totalItemsInDB }}
                </strong>
                Records Found
              </span>
            </v-toolbar>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-chip
              class="mx-1"
              color="green"
              outlined
              @click="showViewGrievanceDialog(item)"
              ><v-icon left> mdi-eye </v-icon> Grievance
            </v-chip>

            <v-icon
              v-if="isDownloadVisible(item.GrievanceDoc)"
              size="22"
              class="mx-1 fitPotPrimaryIcon"
              @click="download(item)"
              >mdi-download</v-icon
            >
          </template>
        </v-data-table>
      </transition>

      <!--start of View Grievance -->
      <v-dialog
        transition="dialog-top-transition"
        v-model="viewGrievanceDialog"
        max-width="800"
        scrollable
        :fullscreen="$vuetify.breakpoint.smAndDown"
        persistent
      >
        <template v-slot:default="dialog">
          <v-overlay :value="isDialogLoaderActive" color="primary">
            <v-progress-circular
              indeterminate
              size="50"
              color="primary"
            ></v-progress-circular>
          </v-overlay>
          <v-card>
            <v-toolbar
              color="primary"
              dark
              :max-height="$vuetify.breakpoint.smAndDown ? 56 : '20vh'"
            >
              <v-toolbar-title class="popup-header">
                View Grievance - {{ applicationId }}</v-toolbar-title
              >
              <v-spacer></v-spacer><v-spacer></v-spacer>
              <v-btn icon dark @click="viewGrievanceDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="py-4 px-2">
              <v-form
                ref="holdingFormAdd"
                v-model="isFormAddValid"
                lazy-validation
              >
                <v-row class="mx-auto d-flex">
                  <v-col cols="12" md="12" class="pt-5 pb-2">
                    <v-textarea
                      v-model="grievancePost"
                      dense
                      outlined
                      maxLength="500"
                      counter="500"
                    >
                      <template #label>
                        Your Grievance
                        <span class="red--text">
                          <strong>*</strong>
                        </span>
                      </template>
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row class="mx-auto d-flex">
                  <v-col cols="12" md="12" class="pt-5 pb-2">
                    <v-textarea
                      v-model="grievanceReply"
                      dense
                      outlined
                      :rules="validationRulesRequired"
                      maxLength="500"
                      counter="500"
                    >
                      <template #label>
                        Your Reply
                        <span class="red--text">
                          <strong>*</strong>
                        </span>
                      </template>
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="justify-end pt-4 pb-6">
              <v-btn
                class="mx-2 primary-button"
                @click="addReply"
                :disabled="!isFormAddValid"
              >
                Save
              </v-btn>
              <v-btn
                class="mx-2 secondary-button"
                @click="viewGrievanceDialog = false"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
      <!--end of View Grievance -->

      <!-- Card End -->
    </v-card>
  </v-container>
</template>
<script>
import { grievanceAdmin } from "./grievance-admin";
export default grievanceAdmin;
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.9s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
