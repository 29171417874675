import { Global } from "../../../helpers/global";
import { globalMixin } from "../../../mixins/globalMixin";
import { ApiService } from "@/helpers/apiService";
import { ApiEndPoint } from "../../../helpers/apiEndPoint";
import axios from "axios";
export const grievance = {
  props: ["userPermissionDataProps"],
  mixins: [globalMixin],
  data() {
    return {
      //newly added
      isLoaderActive: false,
      isDialogLoaderActive: false,
      totalItemsInDB: 0,
      tableItems: [],
      tableDataLoading: false,
      //end
      // Data Table
      tableLoadingDataText: "Loading data",
      tableHeader: [
        {
          text: "#",
          value: "index",
          width: "5%",
          sortable: false,
          align: "start",
        },
        {
          text: "Name",
          value: "Name",
          width: "25%",
          sortable: false,
          align: "start",
        },
        {
          text: "Application Id",
          value: "ApplicationId",
          width: "20%",
          sortable: false,
          align: "start",
        },
        {
          text: "Date",
          value: "GrievanceDate",
          width: "15%",
          sortable: false,
          align: "start",
        },

        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "40%",
          align: "end",
        },
      ],

      pagination: {},
      entity: "Grievance",
      addDialog: false,
      isFormAddValid: false,

      // add edit
      defaultItem: {},
      item: {},
      grievancePost: null,
      grievanceDoc: null,
      GrievanceDocName: null,
      viewGrievanceDialog: false,
      viewReplyDialog: false,
      grievanceReply: null,
      replyByName: null,
      replyDate: null,

      //end
    };
  },

  computed: {
    // For numbering the Data Table Rows
    dataTableRowNumbering() {
      return this.tableItems.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },
  },
  watch: {
    addDialog(value) {
      return value ? true : this.close();
    },
    pagination: {
      handler() {
        this.get();
      },
      deep: true,
    },
  },
  methods: {
    // fetch skills
    get() {
      let { page, itemsPerPage } = this.pagination;

      let payload = {
        userId: secureLS.get(Global.userId),
        page: page,
        pageSize: itemsPerPage,
      };
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.Grievance.getGrievance, payload)
        .then((response) => {
          this.isLoaderActive = false;
          if (response.data.status.isSuccess == "true") {
            this.tableItems = response.data.Data;
            if (response.data.Data.length > 0) {
              this.totalItemsInDB = parseInt(response.data.Data[0].TotalRows);
            }
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    showAddDialog() {
      this.addDialog = true;
    },

    close() {
      this.addDialog = false;
      (this.grievancePost = null), (this.grievanceDoc = null);
    },

    // add
    addItem() {
      if (this.$refs.holdingFormAdd.validate()) {
        this.isDialogLoaderActive = true;
        var postData = {
          UserId: secureLS.get(Global.userId),
          ApplicationId: secureLS.get(Global.userName),
          Name: secureLS.get(Global.fullName),
          GrievancePost: this.grievancePost,
          GrievanceDoc: this.GrievanceDocName,
        };

        ApiService.post(ApiEndPoint.Grievance.postGrievance, postData)
          .then((response) => {
            this.addDialog = false;
            this.isDialogLoaderActive = false;

            if (response.data.status.isSuccess == "true") {
              Global.showSuccessAlert(
                true,
                "success",
                "Grievance posted successfully"
              );
              this.get();
            } else if (response.data.result == "error") {
              Global.showErrorAlert(true, "error", response.data.message);
            }
          })
          .catch((error) => {
            this.addDialog = false;
            this.isDialogLoaderActive = false;
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      }
    },

    grievance_M() {
      if (this.grievanceDoc && this.grievanceDoc.size > 1024 * 1024) {
        Global.showErrorAlert(true, "error", "Maximum file size is 1MB");
        return;
      }
      this.isLoaderActive = true;
      let postData = new FormData();
      if (this.grievanceDoc != null) {
        postData.append("file", this.grievanceDoc);
        postData.append("imageName", secureLS.get(Global.userName));
        postData.append("imageType", "Grievance");
      }
      ApiService.post(ApiEndPoint.CreateUser.FileUpload, postData)

        .then((response) => {
          this.isLoaderActive = false;
          this.GrievanceDocName = response.data.Data;
          Global.showSuccessAlert(
            true,
            "success",
            "File Uploaded successfully"
          );
        })
        .catch((error) => {
          this.isLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },

    isViewReplyVisible(isReplied) {
      if (isReplied == 0) {
        return false;
      } else {
        return true;
      }
    },

    isDownloadVisible(grievanceDoc) {
      if (grievanceDoc == "" || grievanceDoc == null) {
        return false;
      } else {
        return true;
      }
    },
    showViewGrievanceDialog(item) {
      this.grievancePost = item.GrievancePost;
      this.viewGrievanceDialog = true;
    },

    showViewReplyDialog(item) {
      console.log(item);
      this.grievancePost = item.GrievancePost;
      this.grievanceReply = item.GrievanceReply;
      (this.replyByName = item.ReplyByName), (this.replyDate = item.ReplyDate);
      this.viewReplyDialog = true;
    },

     download(item) {
      this.isLoaderActive = true;
      ApiService.download("download/", {
        filename: item.GrievanceDoc,
      })
        .then((response) => {
          this.isLoaderActive = false;
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", item.GrievanceDoc);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },
  },
};
