import { Global } from "@/helpers/global";
import { globalMixin } from "../../../mixins/globalMixin";
import { ApiService } from "@/helpers/apiService";
import { ApiEndPoint } from "../../../helpers/apiEndPoint";
export const newApplication = {
  props: ["userPermissionDataProps"],
  mixins: [globalMixin],
  data() {
    return {
      userPersonalItem: {},
      userContactItem: {},
      userExamDetailItem: [],
      userHighSecondaryDetail: [],
      userOtherDetailItem: null,
      //New Props start
      entity: "New Application",

      e6: 1,
      Locality: ["Home University", "Others University"],
      //New Props End
      UserId: secureLS.get(Global.userId),
      isholdingFormValid: true,
      FName: null,
      MName: null,
      LName: null,
      EmailID: null,
      AdhaarNO: null,
      ABCId: null,
      FatherName: null,
      MobileNumber: null,
      AlterNateMobileNo: null,
      GuardianName: null,
      GuardiaContactNo: null,
      GuardiaEmail: null,
      RelationWithGuardian: null,
      MotherNAME: null,
      IncomePerMonth: null,
      MotherOccupation: null,
      FatherOccupation: null,
      OccupationItems: [],
      Gender: null,
      GenderItems: [],
      Nationality: null,
      NationalityItems: [],
      Religion: null,
      ReligionItems: [],
      Category: null,
      CategoryItems: [],
      Day: null,
      DayItems: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
      ],
      Month: null,
      MonthItems: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      Year: null,
      YearItems: [
        "1950",
        "1951",
        "1952",
        "1953",
        "1954",
        "1955",
        "1956",
        "1957",
        "1958",
        "1959",
        "1960",
        "1961",
        "1962",
        "1963",
        "1964",
        "1965",
        "1966",
        "1967",
        "1968",
        "1969",
        "1970",
        "1971",
        "1972",
        "1973",
        "1974",
        "1975",
        "1976",
        "1977",
        "1978",
        "1979",
        "1980",
        "1981",
        "1982",
        "1983",
        "1984",
        "1985",
        "1986",
        "1987",
        "1988",
        "1989",
        "1990",
        "1991",
        "1992",
        "1993",
        "1994",
        "1995",
        "1996",
        "1997",
        "1998",
        "1999",
        "2000",
        "2001",
        "2002",
        "2003",
        "2004",
        "2005",
        "2006",
        "2007",
        "2008",
        "2009",
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
      ],
      availableDays: [],
      selectedPropertyImage: null,

      Locality: null,
      LocalityItems: [],
      MaterialStatus: null,
      MaterialStatusItems: [],
      EmploymentStatus: null,
      EmploymentStatusItems: [],
      BloodGroup: null,
      BloodGroupItems: [],
      SportsQuota: null,
      SportsQuotaItems: ["Yes", "No"],
      PWDItems: ["Yes", "No"],
      PWD: null,
      CandidatePhotoPath: null,
      PHQuotaType: null,
      PHQuotaTypeItems: [],
      EWS: null,
      EWSItems: ["Yes", "No"],
      RegistrationNumber: null,
      Migrationcertificate: null,
      MigrationItems: ["Yes", "No"],
      RegistrationSession: null,
      // search
      searchText: "",
      // add edit
      defaultItem: {},
      payLoad: {},
      totalItemsInDB: 0,
      items: [],
      values: null,
      addEditDialog: false,
      isFormAddEditValid: false,
      isAddEdit: true,
      addUpdateButtonText: "Add Area",
      addEditText: "Add",
      isLoaderActive: false,
      isDialogLoaderActive: false,

      //Address
      chlSameAddresss: false,
      PresentAddressLIne1: null,
      PresentAddressLine2: null,
      PresentDistrict: null,
      PresentCountry: null,
      PresentCountryItems: [],
      PresentStateItems: [],
      PresentState: null,
      PresentPIN: null,

      PermanentddressLIne1: null,
      PermanentAddressLine2: null,
      PermanentDistrict: null,
      PermanentCountry: null,
      PermanentState: null,
      PermanentCountryItems: [],
      PermanentStateItems: [],
      PermanentPIN: null,
      PO: null,
      PS: null,
      PermanentPO: null,
      PermanentPS: null,
      tableItems: [],
      cityItems: [],
      tableDataLoading: false,

      //Academic

      ISCGPAItems: ["Yes", "No"],
      StatusItems: ["Passed", "Appeared"],
      DivisionItems: [],
      BoardItems: [],
      YearofPassingItems: [],
      ExaminationItems: [],
      academic: [
        {
          UserId: this.UserId,
          Examination: null,
          Status: null,
          Division: null,
          ISCGPA: null,
          ObtainedMarks: null,
          FullMarks: null,
          PercentageMarks: null,
          YearofPassing: null,
          College: null,
          Board: null,
        },
        {
          UserId: this.UserId,
          Examination: null,
          Status: null,
          Division: null,
          ISCGPA: null,
          ObtainedMarks: null,
          FullMarks: null,
          PercentageMarks: null,
          YearofPassing: null,
          College: null,
          Board: null,
        },
        {
          UserId: this.UserId,
          Examination: null,
          Status: null,
          Division: null,
          ISCGPA: null,
          ObtainedMarks: null,
          FullMarks: null,
          PercentageMarks: null,
          YearofPassing: null,
          College: null,
          Board: null,
        },
      ],

      applyFor: [
        {
          UserId: null,
          PaperNo: null,
          Subject: null,
          MarksObtainedTheory: null,
        },
        {
          UserId: null,
          PaperNo: null,
          Subject: null,
          MarksObtainedTheory: null,
        },
        {
          UserId: null,
          PaperNo: null,
          Subject: null,
          MarksObtainedTheory: null,
        },
      ],
      //ApplyedFor
      ApplyForItems: [],
      PassesdItems: [],
      ClassItems: [],

      //Document Upload
      AgeprofImagepath: null,
      AgeprofImagepathName: null,
      LastExam: null,
      LastExamName: null,
      SignatureImagepath: null,
      SignatureImagepathName: null,

      AbcIdImagepath: null,
      AbcIdImagepathName: null,

      photo: null,
      photoName: null,
      dob: null,
      dobName: null,
      XthMarksheet: null,
      XthMarksheetName: null,
      XIIthMarksheet: null,
      XIIthMarksheetName: null,
      GraduationMarksheet: null,
      GraduationMarksheetName: null,
      CategoryCertificate: null,
      CategoryCertificateName: null,

      University_Reg_Certificate: null,
      University_Reg_CertificateName: null,

      Physically_Disablied_Certificate: null,
      Physically_Disablied_CertificateName: null,
      EWS_Certificate: null,
      EWS_CertificateName: null,

      applyForOtherDetailsItem: [],
      signatureImageRule: [],

      abcIdImageRule: [],

      photoImageRule: [],
      dobImageRule: [],
      xImageRule: [],
      xiiImageRule: [],
      gradImageRule: [],
      universityImageRule: [],

      //end

      //excel
      excelFields: {
        ID: "product_mode_id",
        Name: "product_mode",
      },
      excelFileName:
        //  "AreaMaster" + "_" + moment().format("DD/MM/YYYY") + ".xls",
        "AreaMaster" + "_aaaa.xls",
      //end

      isSubmitClicked: false,
      isChekBoxChecked: true,
      isChekBoxChecked1: true,

      secondaryExamItem: [],
      higherSecondaryExamItem: [],
      secondaryYearItem: [],
      higherSecondaryYearItem: [],
      secondaryBoardItem: [],
      higherSecondaryBoardItem: [],
      imageUrl: null,
      preferenceItems1: [],
      preferenceItems2: [],
      preferenceItems3: [],
    };
  },
  created() {
    this.availableDays = this.DayItems;
    this.UserId = secureLS.get(Global.userId);
    this.imageUrl = Global.imageUrl;
    this.getMotherOccupation(1);
    this.getGenderItem(2);
    this.getNationality(3);
    this.getRelegion(4);
    this.getCategory(5);
    this.getLocality(6);
    this.getMaritalStatus(7);
    this.getEmployementStatus(8);
    this.getBloodGroup(9);
    this.getPresentCountry(10);
    this.getPresenState(11);
    this.getPermanentCountry(10);
    this.getPermanentState(11);
    this.getDivision(12);
    this.getBoard(32);
    this.getYearOfPassing(29);
    this.getExamItem(27);
    this.getApplyFor();

    this.getSecondaryExamItem(25);
    this.getHighSecondaryExamItem(26);

    this.getSecondaryYearItem(28);
    this.getHighSecondaryYearItem(28);

    this.getSecondaryBoardItem(30);
    this.getHighSecondaryBoardItem(31);
    this.getPhQuotaTypes(33);
    this.getCandidateDetail();
  },
  computed: {
    isFirstOrSecondAutocompleteSelected() {
      return (
        this.applyFor[0].PaperNo !== null || this.applyFor[1].PaperNo !== null
      );
    },
  },
  watch: {
    SignatureImagepathName(val) {
      this.SignatureImagepathName = val;

      if (
        this.SignatureImagepathName == null ||
        this.SignatureImagepathName == ""
      ) {
        this.signatureImageRule = [(v) => !!v || "Field is required"];
      } else {
        this.signatureImageRule = [];
      }
    },

    AbcIdImagepathName(val) {
      this.AbcIdImagepathName = val;

      if (this.AbcIdImagepathName == null || this.AbcIdImagepathName == "") {
        this.abcIdImageRule = [(v) => !!v || "Field is required"];
      } else {
        this.abcIdImageRule = [];
      }
    },

    photoName(val) {
      this.photoName = val;

      if (this.photoName == null || this.photoName == "") {
        this.photoImageRule = [(v) => !!v || "Field is required"];
      } else {
        this.photoImageRule = [];
      }
    },

    dobName(val) {
      this.dobName = val;

      if (this.dobName == null || this.dobName == "") {
        this.dobImageRule = [(v) => !!v || "Field is required"];
      } else {
        this.dobImageRule = [];
      }
    },

    XthMarksheetName(val) {
      this.XthMarksheetName = val;

      if (this.XthMarksheetName == null || this.XthMarksheetName == "") {
        this.xImageRule = [(v) => !!v || "Field is required"];
      } else {
        this.xImageRule = [];
      }
    },

    XIIthMarksheetName(val) {
      this.XIIthMarksheetName = val;

      if (this.XIIthMarksheetName == null || this.XIIthMarksheetName == "") {
        this.xiiImageRule = [(v) => !!v || "Field is required"];
      } else {
        this.xiiImageRule = [];
      }
    },

    GraduationMarksheetName(val) {
      this.GraduationMarksheetName = val;

      if (
        this.GraduationMarksheetName == null ||
        this.GraduationMarksheetName == ""
      ) {
        this.gradImageRule = [(v) => !!v || "Field is required"];
      } else {
        this.gradImageRule = [];
      }
    },

    University_Reg_CertificateName(val) {
      this.University_Reg_CertificateName = val;

      if (
        this.University_Reg_CertificateName == null ||
        this.University_Reg_CertificateName == ""
      ) {
        this.universityImageRule = [(v) => !!v || "Field is required"];
      } else {
        this.universityImageRule = [];
      }
    },
    academic: {
      handler: "updateFullMarksValue",
      deep: true,
    },
    Locality(newVal) {
    
        this.filterExaminationItems();
      
    },
  },
  methods: {
    filterExaminationItems() {
      if (this.Locality === "Home University") {
        this.ExaminationItems = this.ExaminationItems.filter(
          (item) => item !== "Others"
        );
      
      }
      else
      {
        if (!this.ExaminationItems.some((item) => item === "Others")) {
          this.ExaminationItems.push("Others");
        }
      }
    },
    getPhQuotaTypes(typeId) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.GetMaster, {
        typeId: typeId,
      })
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            this.PHQuotaTypeItems = response.data.Data;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },
    getMotherOccupation(typeId) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.GetMaster, {
        typeId: typeId,
      })
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            this.OccupationItems = response.data.Data;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    getGenderItem(typeId) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.GetMaster, {
        typeId: typeId,
      })
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            this.GenderItems = response.data.Data;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    getNationality(typeId) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.GetMaster, {
        typeId: typeId,
      })
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            this.NationalityItems = response.data.Data;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    getRelegion(typeId) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.GetMaster, {
        typeId: typeId,
      })
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            this.ReligionItems = response.data.Data;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },
    getCategory(typeId) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.GetMaster, {
        typeId: typeId,
      })
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            this.CategoryItems = response.data.Data;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    getLocality(typeId) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.GetMaster, {
        typeId: typeId,
      })
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            this.LocalityItems = response.data.Data;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    getMaritalStatus(typeId) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.GetMaster, {
        typeId: typeId,
      })
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            this.MaterialStatusItems = response.data.Data;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    getEmployementStatus(typeId) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.GetMaster, {
        typeId: typeId,
      })
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            this.EmploymentStatusItems = response.data.Data;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    getBloodGroup(typeId) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.GetMaster, {
        typeId: typeId,
      })
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            this.BloodGroupItems = response.data.Data;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    getPresentCountry(typeId) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.GetMaster, {
        typeId: typeId,
      })
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            this.PresentCountryItems = response.data.Data;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    getPresenState(typeId) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.GetMaster, {
        typeId: typeId,
      })
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            this.PresentStateItems = response.data.Data;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    getPermanentCountry(typeId) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.GetMaster, {
        typeId: typeId,
      })
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            this.PermanentCountryItems = response.data.Data;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    getPermanentState(typeId) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.GetMaster, {
        typeId: typeId,
      })
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            this.PermanentStateItems = response.data.Data;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    getDivision(typeId) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.GetMaster, {
        typeId: typeId,
      })
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            this.DivisionItems = response.data.Data;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    getBoard(typeId) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.GetMaster, {
        typeId: typeId,
      })
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            this.BoardItems = response.data.Data;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    getSecondaryBoardItem(typeId) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.GetMaster, {
        typeId: typeId,
      })
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            this.secondaryBoardItem = response.data.Data;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    getHighSecondaryBoardItem(typeId) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.GetMaster, {
        typeId: typeId,
      })
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            this.higherSecondaryBoardItem = response.data.Data;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    getYearOfPassing(typeId) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.GetMaster, {
        typeId: typeId,
      })
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            this.YearofPassingItems = response.data.Data;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    getSecondaryYearItem(typeId) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.GetMaster, {
        typeId: typeId,
      })
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            this.secondaryYearItem = response.data.Data;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    getHighSecondaryYearItem(typeId) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.GetMaster, {
        typeId: typeId,
      })
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            this.higherSecondaryYearItem = response.data.Data;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    getExamItem(typeId) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.GetMaster, {
        typeId: typeId,
      })
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            this.ExaminationItems = response.data.Data;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    getSecondaryExamItem(typeId) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.GetMaster, {
        typeId: typeId,
      })
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            this.secondaryExamItem = response.data.Data;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    getHighSecondaryExamItem(typeId) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.GetMaster, {
        typeId: typeId,
      })
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            this.higherSecondaryExamItem = response.data.Data;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    sameAddress() {
      if (this.chlSameAddresss == true) {
        (this.PermanentddressLIne1 = this.PresentAddressLIne1),
          (this.PermanentAddressLine2 = this.PresentAddressLine2),
          (this.PermanentDistrict = this.PresentDistrict),
          (this.PermanentCountry = this.PresentCountry),
          (this.PermanentState = this.PresentState),
          (this.PermanentPIN = this.PresentPIN);
        this.PermanentPO = this.PO;
        this.PermanentPS = this.PS;
      } else {
        (this.PermanentddressLIne1 = null),
          (this.PermanentAddressLine2 = null),
          (this.PermanentDistrict = null),
          (this.PermanentCountry = null),
          (this.PermanentState = null),
          (this.PermanentPIN = null);
        this.PermanentPO = null;
        this.PermanentPS = null;
      }
    },

    uploadImage() {
      if (this.selectedPropertyImage.name.includes(".jpg")) {
        this.isLoaderActive = true;
        let postData = new FormData();
        if (this.selectedPropertyImage != null) {
          postData.append("file", this.selectedPropertyImage);
        }

        ApiService.post(ApiEndPoint.CreateUser.FileUpload, postData)

          .then((response) => {
            this.isLoaderActive = false;

            this.CandidatePhotoPath = response.data.Data;
            Global.showSuccessAlert(
              true,
              "success",
              "File Uploaded successfully"
            );
          })
          .catch((error) => {
            this.isLoaderActive = false;
            if (error.response.status != 401 && error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      } else {
        Global.showErrorAlert(true, "error", "Only JPG file allowed");
      }
    },

    saveImageDetails() {
      if (this.$refs.holdingFormDocument.validate()) {
        var postData = [
          {
            UserId: this.UserId,
            AgeprofImagepath: this.AgeprofImagepathName,
            LastExam: this.LastExamName,
            SignatureImagepath: this.SignatureImagepathName,
            ABCIDDOC: this.AbcIdImagepathName,
            photo: this.photoName,
            dob: this.dobName,
            XthMarksheet: this.XthMarksheetName,
            XIIthMarksheet: this.XIIthMarksheetName,
            GraduationMarksheet: this.GraduationMarksheetName,
            CategoryCertificate: this.CategoryCertificateName,

            University_Reg_Certificate: this.University_Reg_CertificateName,

            Physically_Disablied_Certificate:
              this.Physically_Disablied_CertificateName,
            EWS_Certificate: this.EWS_CertificateName,
          },
        ];
        if (
          this.SignatureImagepathName == null ||
          this.SignatureImagepathName == ""
        ) {
          Global.showErrorAlert(true, "error", "Upload signature image");
          return;
        }
        if (this.photoName == null || this.photoName == "") {
          Global.showErrorAlert(true, "error", "Upload Photo");
          return;
        }
        if (this.dobName == null || this.dobName == "") {
          Global.showErrorAlert(true, "error", "Upload DOB certificate");
          return;
        }
        if (this.XthMarksheetName == null || this.XthMarksheetName == "") {
          Global.showErrorAlert(true, "error", "Upload 10th Marksheet");
          return;
        }
        if (this.XIIthMarksheetName == null || this.XIIthMarksheetName == "") {
          Global.showErrorAlert(true, "error", "Upload 12th Marksheet");
          return;
        }
        if (
          this.GraduationMarksheetName == null ||
          this.GraduationMarksheetName == ""
        ) {
          Global.showErrorAlert(true, "error", "Upload Graduation Marksheet");
          return;
        }
        if (
          this.University_Reg_CertificateName == null ||
          this.University_Reg_CertificateName == ""
        ) {
          Global.showErrorAlert(
            true,
            "error",
            "Upload University Registration Certificate"
          );
          return;
        }
        if (this.Category != "Unreserved(UR)") {
          if (this.CategoryCertificateName == null) {
            Global.showErrorAlert(true, "error", "Upload Caste certificate");
            return;
          }
        }
        if (this.PWD == "Yes") {
          if (this.Physically_Disablied_CertificateName == null) {
            Global.showErrorAlert(
              true,
              "error",
              "Upload Physically Disablied Certificate"
            );
            return;
          }
        }
        if (this.EWS == "Yes") {
          if (this.EWS_CertificateName == null) {
            Global.showErrorAlert(true, "error", "Upload EWS Certificate");
            return;
          }
        }
        if (this.ABCId) {
          if (
            this.AbcIdImagepathName == null ||
            this.AbcIdImagepathName == ""
          ) {
            Global.showErrorAlert(true, "error", "Upload ABC Id image");
            return;
          }
        }

        this.isLoaderActive = true;
        ApiService.post(
          ApiEndPoint.CreateUser.CreateCandidateOtherDetail,
          postData
        )
          .then((response) => {
            this.isLoaderActive = false;

            if (response.data.status.isSuccess == "true") {
              this.isSubmitClicked = true;

              Global.showSuccessAlert(
                true,
                "success",
                "Application saved successfully"
              );
            } else if (response.data.result == "error") {
              Global.showErrorAlert(true, "error", response.data.message);
            }
          })
          .catch((error) => {
            this.isLoaderActive = false;
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      }
    },
    // AgeprofImagepath_M() {
    //   if (this.AgeprofImagepath.name.includes(".jpg")) {
    //     this.isLoaderActive = true;
    //     let postData = new FormData();
    //     if (this.AgeprofImagepath != null) {
    //       postData.append("file", this.AgeprofImagepath);
    //     }
    //     ApiService.post(ApiEndPoint.CreateUser.FileUpload, postData)

    //       .then((response) => {
    //         this.isLoaderActive = false;
    //         this.AgeprofImagepathName = response.data.Data;
    //         Global.showSuccessAlert(
    //           true,
    //           "success",
    //           "File Uploaded successfully"
    //         );
    //       })
    //       .catch((error) => {
    //         this.isLoaderActive = false;
    //         if (error.response.status != 401 && error.response.status != 403) {
    //           Global.showErrorAlert(true, "error", "Something went wrong");
    //         }
    //       });
    //   } else {
    //     Global.showErrorAlert(true, "error", "Only JPG file allowed");
    //   }
    // },
    // LastExam_M() {
    //   if (this.LastExam.name.includes(".jpg")) {
    //     this.isLoaderActive = true;
    //     let postData = new FormData();
    //     if (this.LastExam != null) {
    //       postData.append("file", this.LastExam);
    //     }
    //     ApiService.post(ApiEndPoint.CreateUser.FileUpload, postData)

    //       .then((response) => {
    //         this.isLoaderActive = false;
    //         this.LastExamName = response.data.Data;
    //         Global.showSuccessAlert(
    //           true,
    //           "success",
    //           "File Uploaded successfully"
    //         );
    //       })
    //       .catch((error) => {
    //         this.isLoaderActive = false;
    //         if (error.response.status != 401 && error.response.status != 403) {
    //           Global.showErrorAlert(true, "error", "Something went wrong");
    //         }
    //       });
    //   } else {
    //     Global.showErrorAlert(true, "error", "Only JPG file allowed");
    //   }
    // },

    SignatureImagepath_M() {
      if (this.SignatureImagepath.name.includes(".jpg")) {
        if (
          this.SignatureImagepath &&
          this.SignatureImagepath.size > 1024 * 1024
        ) {
          Global.showErrorAlert(true, "error", "Maximum file size is 1MB");
          return;
        }
        this.isLoaderActive = true;
        let postData = new FormData();
        if (this.SignatureImagepath != null) {
          postData.append("file", this.SignatureImagepath);
          postData.append("imageName", secureLS.get(Global.userName));
          postData.append("imageType", "Signature");
        }
        ApiService.post(ApiEndPoint.CreateUser.FileUpload, postData)

          .then((response) => {
            this.isLoaderActive = false;
            this.SignatureImagepathName = response.data.Data;
            Global.showSuccessAlert(
              true,
              "success",
              "File Uploaded successfully"
            );
          })
          .catch((error) => {
            this.isLoaderActive = false;
            if (error.response.status != 401 && error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      } else {
        Global.showErrorAlert(true, "error", "Only JPG file allowed");
        this.$refs.SignatureImagepathRef.reset();
      }
    },

    AbcIdImagepath_M() {
      if (this.AbcIdImagepath.name.includes(".jpg")) {
        if (this.AbcIdImagepath && this.AbcIdImagepath.size > 1024 * 1024) {
          Global.showErrorAlert(true, "error", "Maximum file size is 1MB");
          return;
        }
        this.isLoaderActive = true;
        let postData = new FormData();
        if (this.AbcIdImagepath != null) {
          postData.append("file", this.AbcIdImagepath);
          postData.append("imageName", secureLS.get(Global.userName));
          postData.append("imageType", "ABC ID");
        }
        ApiService.post(ApiEndPoint.CreateUser.FileUpload, postData)

          .then((response) => {
            this.isLoaderActive = false;
            this.AbcIdImagepathName = response.data.Data;
            Global.showSuccessAlert(
              true,
              "success",
              "File Uploaded successfully"
            );
          })
          .catch((error) => {
            this.isLoaderActive = false;
            if (error.response.status != 401 && error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      } else {
        Global.showErrorAlert(true, "error", "Only JPG file allowed");
        this.$refs.AbcIdImagepathRef.reset();
      }
    },

    photo_M() {
      if (this.photo.name.includes(".jpg")) {
        if (this.photo && this.photo.size > 1024 * 1024) {
          Global.showErrorAlert(true, "error", "Maximum file size is 1MB");
          return;
        }
        this.isLoaderActive = true;
        let postData = new FormData();
        if (this.photo != null) {
          postData.append("file", this.photo);
          postData.append("imageName", secureLS.get(Global.userName));
          postData.append("imageType", "Photo");
        }
        ApiService.post(ApiEndPoint.CreateUser.FileUpload, postData)

          .then((response) => {
            this.isLoaderActive = false;
            this.photoName = response.data.Data;
            Global.showSuccessAlert(
              true,
              "success",
              "File Uploaded successfully"
            );
          })
          .catch((error) => {
            this.isLoaderActive = false;
            if (error.response.status != 401 && error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      } else {
        Global.showErrorAlert(true, "error", "Only JPG file allowed");
        this.$refs.photoRef.reset();
      }
    },

    dob_M() {
      if (this.dob.name.includes(".jpg")) {
        if (this.dob && this.dob.size > 1024 * 1024) {
          Global.showErrorAlert(true, "error", "Maximum file size is 1MB");
          return;
        }
        this.isLoaderActive = true;
        let postData = new FormData();
        if (this.dob != null) {
          postData.append("file", this.dob);
          postData.append("imageName", secureLS.get(Global.userName));
          postData.append("imageType", "DOB");
        }
        ApiService.post(ApiEndPoint.CreateUser.FileUpload, postData)

          .then((response) => {
            this.isLoaderActive = false;
            this.dobName = response.data.Data;
            Global.showSuccessAlert(
              true,
              "success",
              "File Uploaded successfully"
            );
          })
          .catch((error) => {
            this.isLoaderActive = false;
            if (error.response.status != 401 && error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      } else {
        Global.showErrorAlert(true, "error", "Only JPG file allowed");
        this.$refs.dobRef.reset();
      }
    },

    XthMarksheet_M() {
      if (this.XthMarksheet.name.includes(".jpg")) {
        if (this.XthMarksheet && this.XthMarksheet.size > 1024 * 1024) {
          Global.showErrorAlert(true, "error", "Maximum file size is 1MB");
          return;
        }
        this.isLoaderActive = true;
        let postData = new FormData();
        if (this.XthMarksheet != null) {
          postData.append("file", this.XthMarksheet);
          postData.append("imageName", secureLS.get(Global.userName));
          postData.append("imageType", "XthMarksheet");
        }
        ApiService.post(ApiEndPoint.CreateUser.FileUpload, postData)

          .then((response) => {
            this.isLoaderActive = false;
            this.XthMarksheetName = response.data.Data;
            Global.showSuccessAlert(
              true,
              "success",
              "File Uploaded successfully"
            );
          })
          .catch((error) => {
            this.isLoaderActive = false;
            if (error.response.status != 401 && error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      } else {
        Global.showErrorAlert(true, "error", "Only JPG file allowed");
        this.$refs.XthMarksheetRef.reset();
      }
    },
    XIIthMarksheet_M() {
      if (this.XIIthMarksheet.name.includes(".jpg")) {
        if (this.XIIthMarksheet && this.XIIthMarksheet.size > 1024 * 1024) {
          Global.showErrorAlert(true, "error", "Maximum file size is 1MB");
          return;
        }
        this.isLoaderActive = true;
        let postData = new FormData();
        if (this.XIIthMarksheet != null) {
          postData.append("file", this.XIIthMarksheet);
          postData.append("imageName", secureLS.get(Global.userName));
          postData.append("imageType", "XIIthMarksheet");
        }
        ApiService.post(ApiEndPoint.CreateUser.FileUpload, postData)

          .then((response) => {
            this.isLoaderActive = false;
            this.XIIthMarksheetName = response.data.Data;
            Global.showSuccessAlert(
              true,
              "success",
              "File Uploaded successfully"
            );
          })
          .catch((error) => {
            this.isLoaderActive = false;
            if (error.response.status != 401 && error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      } else {
        Global.showErrorAlert(true, "error", "Only JPG file allowed");
        this.$refs.XIIthMarksheetRef.reset();
      }
    },

    GraduationMarksheet_M() {
      if (this.GraduationMarksheet.name.includes(".jpg")) {
        if (
          this.GraduationMarksheet &&
          this.GraduationMarksheet.size > 1024 * 1024
        ) {
          Global.showErrorAlert(true, "error", "Maximum file size is 1MB");
          return;
        }
        this.isLoaderActive = true;
        let postData = new FormData();
        if (this.GraduationMarksheet != null) {
          postData.append("file", this.GraduationMarksheet);
          postData.append("imageName", secureLS.get(Global.userName));
          postData.append("imageType", "GraduationMarksheet");
        }
        ApiService.post(ApiEndPoint.CreateUser.FileUpload, postData)

          .then((response) => {
            this.isLoaderActive = false;
            this.GraduationMarksheetName = response.data.Data;
            Global.showSuccessAlert(
              true,
              "success",
              "File Uploaded successfully"
            );
          })
          .catch((error) => {
            this.isLoaderActive = false;
            if (error.response.status != 401 && error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      } else {
        Global.showErrorAlert(true, "error", "Only JPG file allowed");
        this.$refs.GraduationMarksheetRef.reset();
      }
    },

    CategoryCertificate_M() {
      if (this.CategoryCertificate.name.includes(".jpg")) {
        if (
          this.CategoryCertificate &&
          this.CategoryCertificate.size > 1024 * 1024
        ) {
          Global.showErrorAlert(true, "error", "Maximum file size is 1MB");
          return;
        }
        this.isLoaderActive = true;
        let postData = new FormData();
        if (this.CategoryCertificate != null) {
          postData.append("file", this.CategoryCertificate);
          postData.append("imageName", secureLS.get(Global.userName));
          postData.append("imageType", "Caste");
        }
        ApiService.post(ApiEndPoint.CreateUser.FileUpload, postData)

          .then((response) => {
            this.isLoaderActive = false;
            this.CategoryCertificateName = response.data.Data;
            Global.showSuccessAlert(
              true,
              "success",
              "File Uploaded successfully"
            );
          })
          .catch((error) => {
            this.isLoaderActive = false;
            if (error.response.status != 401 && error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      } else {
        Global.showErrorAlert(true, "error", "Only JPG file allowed");
        this.$refs.CategoryCertificateRef.reset();
      }
    },

    University_Reg_Certificate_M() {
      if (this.University_Reg_Certificate.name.includes(".jpg")) {
        if (
          this.University_Reg_Certificate &&
          this.University_Reg_Certificate.size > 1024 * 1024
        ) {
          Global.showErrorAlert(true, "error", "Maximum file size is 1MB");
          return;
        }
        this.isLoaderActive = true;
        let postData = new FormData();
        if (this.University_Reg_Certificate != null) {
          postData.append("file", this.University_Reg_Certificate);
          postData.append("imageName", secureLS.get(Global.userName));
          postData.append("imageType", "University");
        }
        ApiService.post(ApiEndPoint.CreateUser.FileUpload, postData)

          .then((response) => {
            this.isLoaderActive = false;
            this.University_Reg_CertificateName = response.data.Data;
            Global.showSuccessAlert(
              true,
              "success",
              "File Uploaded successfully"
            );
          })
          .catch((error) => {
            this.isLoaderActive = false;
            if (error.response.status != 401 && error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      } else {
        Global.showErrorAlert(true, "error", "Only JPG file allowed");
        this.$refs.University_Reg_CertificateRef.reset();
      }
    },

    Physically_Disablied_Certificate_M() {
      if (this.Physically_Disablied_Certificate.name.includes(".jpg")) {
        if (
          this.Physically_Disablied_Certificate &&
          this.Physically_Disablied_Certificate.size > 1024 * 1024
        ) {
          Global.showErrorAlert(true, "error", "Maximum file size is 1MB");
          return;
        }
        this.isLoaderActive = true;
        let postData = new FormData();
        if (this.Physically_Disablied_Certificate != null) {
          postData.append("file", this.Physically_Disablied_Certificate);
          postData.append("imageName", secureLS.get(Global.userName));
          postData.append("imageType", "PH");
        }
        ApiService.post(ApiEndPoint.CreateUser.FileUpload, postData)

          .then((response) => {
            this.isLoaderActive = false;
            this.Physically_Disablied_CertificateName = response.data.Data;
            Global.showSuccessAlert(
              true,
              "success",
              "File Uploaded successfully"
            );
          })
          .catch((error) => {
            this.isLoaderActive = false;
            if (error.response.status != 401 && error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      } else {
        Global.showErrorAlert(true, "error", "Only JPG file allowed");
        this.$refs.Physically_Disablied_CertificateRef.reset();
      }
    },
    EWS_Certificate_M() {
      if (this.EWS_Certificate.name.includes(".jpg")) {
        if (this.EWS_Certificate && this.EWS_Certificate.size > 1024 * 1024) {
          Global.showErrorAlert(true, "error", "Maximum file size is 1MB");
          return;
        }
        this.isLoaderActive = true;
        let postData = new FormData();
        if (this.EWS_Certificate != null) {
          postData.append("file", this.EWS_Certificate);
          postData.append("imageName", secureLS.get(Global.userName));
          postData.append("imageType", "EWS");
        }
        ApiService.post(ApiEndPoint.CreateUser.FileUpload, postData)

          .then((response) => {
            this.isLoaderActive = false;
            this.EWS_CertificateName = response.data.Data;
            Global.showSuccessAlert(
              true,
              "success",
              "File Uploaded successfully"
            );
          })
          .catch((error) => {
            this.isLoaderActive = false;
            if (error.response.status != 401 && error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      } else {
        Global.showErrorAlert(true, "error", "Only JPG file allowed");
        this.$refs.EWS_CertificateRef.reset();
      }
    },
    savePersonalDetails() {
      if (this.$refs.holdingForm.validate()) {
        this.isLoaderActive = true;
        var postData = [
          {
            UserId: this.UserId,
            FName: this.FName.toUpperCase(),
            MName: this.MName != null ? this.MName.toUpperCase() : this.MName,
            LName: this.LName.toUpperCase(),
            EmailID: this.EmailID,
            AdhaarNO: this.AdhaarNO,
            ABCId: this.ABCId,
            MotherNAME: this.MotherNAME.toUpperCase(),
            FatherName: this.FatherName.toUpperCase(),
            MobileNumber: this.MobileNumber,
            AlterNateMobileNo: this.AlterNateMobileNo,
            MotherOccupation: this.MotherOccupation,
            FatherOccupation: this.FatherOccupation,
            IncomePerMonth: this.IncomePerMonth,
            DateofBirth: this.Year + "-" + this.Month + "-" + this.Day,
            Gender: this.Gender,
            Nationality: this.Nationality,
            Religion: this.Religion,
            Category: this.Category,
            CandidatePhotoPath: this.CandidatePhotoPath,
            Locality: this.Locality,
            MaterialStatus: this.MaterialStatus,
            EmploymentStatus: this.EmploymentStatus,
            BloodGroup: this.BloodGroup,
            PWD: this.PWD,
            SportsQuota: this.SportsQuota,
            GuardianName: this.GuardianName,
            GuardiaContactNo: this.GuardiaContactNo,
            GuardiaEmail: this.GuardiaEmail,
            RelationWithGuardian: this.RelationWithGuardian,
            PHQuotaType: this.PHQuotaType,
            EWS: this.EWS,
            RegistrationNumber: this.RegistrationNumber,
            Migrationcertificate: this.Migrationcertificate,
            RegistrationSession: this.RegistrationSession,
          },
        ];
        ApiService.post(
          ApiEndPoint.CreateUser.CreateCandidatePersonalDetail,
          postData
        )
          .then((response) => {
            this.isLoaderActive = false;

            if (response.data.status.isSuccess == "true") {
              Global.showSuccessAlert(
                true,
                "success",
                "Application saved successfully"
              );
              this.e6 = 2;
            } else if (response.data.result == "error") {
              Global.showErrorAlert(true, "error", response.data.message);
            }
          })
          .catch((error) => {
            this.isLoaderActive = false;
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      }
    },

    saveAddressDetails() {
      if (this.$refs.holdingFormAddress.validate()) {
        this.isLoaderActive = true;
        var postData = [
          {
            UserId: this.UserId,
            PresentAddressLIne1: this.PresentAddressLIne1,
            PresentAddressLine2: this.PresentAddressLine2,
            PresentDistrict: this.PresentDistrict,
            PresentCountry: this.PresentCountry,
            PresentState: this.PresentState,
            PresentPIN: this.PresentPIN,
            PermanentddressLIne1: this.PermanentddressLIne1,
            PermanentAddressLine2: this.PermanentAddressLine2,
            PermanentDistrict: this.PermanentDistrict,
            PermanentCountry: this.PermanentCountry,
            PermanentState: this.PermanentState,
            PermanentPIN: this.PermanentPIN,
            PO: this.PO,
            PS: this.PS,
            PermanentPO: this.PermanentPO,
            PermanentPS: this.PermanentPS,
          },
        ];
        ApiService.post(
          ApiEndPoint.CreateUser.CreateCandidateContactDetail,
          postData
        )
          .then((response) => {
            this.isLoaderActive = false;

            if (response.data.status.isSuccess == "true") {
              Global.showSuccessAlert(
                true,
                "success",
                "Address details saved successfully"
              );
              this.e6 = 3;
            } else if (response.data.result == "error") {
              Global.showErrorAlert(true, "error", response.data.message);
            }
          })
          .catch((error) => {
            this.isLoaderActive = false;
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      }
    },

    saveAcademicDetails() {
      this.academic[0].UserId = this.UserId;
      this.academic[1].UserId = this.UserId;
      this.academic[2].UserId = this.UserId;
      if (this.$refs.holdingFormAcademic.validate()) {
        this.isLoaderActive = true;

        ApiService.post(
          ApiEndPoint.CreateUser.CreateCandidateExamDetail,
          this.academic
        )
          .then((response) => {
            this.isLoaderActive = false;

            if (response.data.status.isSuccess == "true") {
              Global.showSuccessAlert(
                true,
                "success",
                "Exam Details saved successfully"
              );
              this.e6 = 4;
            } else if (response.data.result == "error") {
              Global.showErrorAlert(true, "error", response.data.message);
            }
          })
          .catch((error) => {
            this.isLoaderActive = false;
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      }
    },

    saveHSDetails() {
      this.applyFor[0].UserId = this.UserId;
      this.applyFor[1].UserId = this.UserId;
      this.applyFor[2].UserId = this.UserId;
      if (this.$refs.holdingFormHS.validate()) {
        this.isLoaderActive = true;

        ApiService.post(
          ApiEndPoint.CreateUser.CreateCandidateHigherSecondaryDetail,
          this.applyFor
        )
          .then((response) => {
            this.isLoaderActive = false;

            if (response.data.status.isSuccess == "true") {
              Global.showSuccessAlert(
                true,
                "success",
                "Subject Details saved successfully"
              );
              this.e6 = 5;
            } else if (response.data.result == "error") {
              Global.showErrorAlert(true, "error", response.data.message);
            }
          })
          .catch((error) => {
            this.isLoaderActive = false;
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      }
    },
    getDropDownList(typeId) {
      //this.isLoaderActive = true;
      return new ApiService.get(ApiEndPoint.CreateUser.GetMaster, {
        typeId: typeId,
      })
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            //this.OccupationItems = response.data.Data;

            return response.data.Data;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    getApplyFor() {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.GetApplyFor, {})
        .then((response) => {
          this.isLoaderActive = false;
          if (response.data.status.isSuccess == "true") {
            this.ApplyForItems = response.data.Data;

            return response.data.Data;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    GetApplyForOtherDetails(applyFor, index) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.GetApplyForOtherDetails, {
        applyFor: applyFor,
      })
        .then((response) => {
          this.isLoaderActive = false;
          if (response.data.status.isSuccess == "true") {
            this.applyForOtherDetailsItem = response.data.Data[index];
            // this.applyFor[index].Subject = response.data.Data[0].Degree;
            // this.applyFor[index].MarksObtainedTheory =
            //   response.data.Data[0].Subject;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },

    getCandidateDetail() {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.getCandidateDetail, {
        userId: secureLS.get(Global.userId),
      })
        .then((response) => {
          this.isLoaderActive = false;
          if (response.data.status.isSuccess == "true") {
            if (response.data.Data.personalDetail != null) {
              this.userPersonalItem = response.data.Data.personalDetail;

              this.FName = this.userPersonalItem.FName;
              this.LName = this.userPersonalItem.LName;
              this.EmailID = this.userPersonalItem.EmailID;
              this.AdhaarNO = this.userPersonalItem.AdhaarNO;
              this.ABCId = this.userPersonalItem.ABCId;
              this.MobileNumber = this.userPersonalItem.MobileNumber;
              this.AlterNateMobileNo = this.userPersonalItem.AlterNateMobileNo;
              this.GuardianName = this.userPersonalItem.GuardianName;
              this.GuardiaContactNo = this.userPersonalItem.GuardiaContactNo;
              this.GuardiaEmail = this.userPersonalItem.GuardiaEmail;
              this.RelationWithGuardian =
                this.userPersonalItem.RelationWithGuardian;
              this.MotherNAME = this.userPersonalItem.MotherNAME;
              this.FatherName = this.userPersonalItem.FatherName;
              this.IncomePerMonth = this.userPersonalItem.IncomePerMonth;
              this.MotherOccupation = this.userPersonalItem.MotherOccupation;
              this.FatherOccupation = this.userPersonalItem.FatherOccupation;
              this.Gender = this.userPersonalItem.Gender;
              this.Nationality = this.userPersonalItem.Nationality;
              this.Religion = this.userPersonalItem.Religion;
              this.Category = this.userPersonalItem.Category;
              this.Day = this.userPersonalItem.DateofBirth.substring(
                this.userPersonalItem.DateofBirth.lastIndexOf("-") + 1
              );
              this.Month = this.userPersonalItem.DateofBirth.substring(
                this.userPersonalItem.DateofBirth.indexOf("-") + 1,
                this.userPersonalItem.DateofBirth.lastIndexOf("-")
              );
              this.Year = this.userPersonalItem.DateofBirth.substring(
                0,
                this.userPersonalItem.DateofBirth.indexOf("-")
              );
              this.CandidatePhotoPath =
                this.userPersonalItem.CandidatePhotoPath;
              this.Locality = this.userPersonalItem.Locality;
              this.MaterialStatus = this.userPersonalItem.MaterialStatus;
              this.EmploymentStatus = this.userPersonalItem.EmploymentStatus;
              this.BloodGroup = this.userPersonalItem.BloodGroup;
              this.SportsQuota = this.userPersonalItem.SportsQuota;
              this.PWD = this.userPersonalItem.PWD;
              this.PHQuotaType = this.userPersonalItem.PHQuotaType;
              this.EWS = this.userPersonalItem.EWS;
              this.RegistrationNumber =
                this.userPersonalItem.RegistrationNumber;
              this.Migrationcertificate =
                this.userPersonalItem.Migrationcertificate;
              this.RegistrationSession =
                this.userPersonalItem.RegistrationSession;
            }

            if (response.data.Data.contactDetail != null) {
              this.userContactItem = response.data.Data.contactDetail;
              this.PresentAddressLIne1 =
                this.userContactItem.PresentAddressLIne1;
              this.PresentAddressLine2 =
                this.userContactItem.PresentAddressLine2;
              this.PresentDistrict = this.userContactItem.PresentDistrict;
              this.PresentCountry = this.userContactItem.PresentCountry;

              this.PresentState = this.userContactItem.PresentState;
              this.PresentPIN = this.userContactItem.PresentPIN;

              this.PermanentddressLIne1 =
                this.userContactItem.PermanentddressLIne1;
              this.PermanentAddressLine2 =
                this.userContactItem.PermanentAddressLine2;
              this.PermanentDistrict = this.userContactItem.PermanentDistrict;
              this.PermanentCountry = this.userContactItem.PermanentCountry;
              this.PermanentState = this.userContactItem.PermanentState;

              this.PermanentPIN = this.userContactItem.PermanentPIN;
              this.PO = this.userContactItem.PO;
              this.PS = this.userContactItem.PS;
              this.PermanentPO = this.userContactItem.PermanentPO;
              this.PermanentPS = this.userContactItem.PermanentPS;
            }
            if (response.data.Data.examDetail.length > 0) {
              this.userExamDetailItem = response.data.Data.examDetail;

              this.academic[0].UserId = this.UserId;

              this.academic[0].Examination =
                this.userExamDetailItem[0].Examination;
              this.academic[0].Status = this.userExamDetailItem[0].Status;
              this.academic[0].Division = this.userExamDetailItem[0].Division;
              this.academic[0].ISCGPA = this.userExamDetailItem[0].ISCGPA;
              this.academic[0].ObtainedMarks =
                this.userExamDetailItem[0].ObtainedMarks;
              this.academic[0].FullMarks = this.userExamDetailItem[0].FullMarks;
              this.academic[0].PercentageMarks =
                this.userExamDetailItem[0].PercentageMarks;
              this.academic[0].YearofPassing =
                this.userExamDetailItem[0].YearofPassing;
              this.academic[0].College = this.userExamDetailItem[0].College;
              this.academic[0].Board = this.userExamDetailItem[0].Board;
              this.academic[0].CGPAScore = this.userExamDetailItem[0].CGPAScore;

              this.academic[1].UserId = this.UserId;
              this.academic[1].Examination =
                this.userExamDetailItem[1].Examination;
              this.academic[1].Status = this.userExamDetailItem[1].Status;
              this.academic[1].Division = this.userExamDetailItem[1].Division;
              this.academic[1].ISCGPA = this.userExamDetailItem[1].ISCGPA;
              this.academic[1].ObtainedMarks =
                this.userExamDetailItem[1].ObtainedMarks;
              this.academic[1].FullMarks = this.userExamDetailItem[1].FullMarks;
              this.academic[1].PercentageMarks =
                this.userExamDetailItem[1].PercentageMarks;
              this.academic[1].YearofPassing =
                this.userExamDetailItem[1].YearofPassing;
              this.academic[1].College = this.userExamDetailItem[1].College;
              this.academic[1].Board = this.userExamDetailItem[1].Board;
              this.academic[1].CGPAScore = this.userExamDetailItem[1].CGPAScore;
              this.academic[2].UserId = this.UserId;
              this.academic[2].Examination =
                this.userExamDetailItem[2].Examination;
              this.academic[2].Status = this.userExamDetailItem[2].Status;
              this.academic[2].Division = this.userExamDetailItem[2].Division;
              this.academic[2].ISCGPA = this.userExamDetailItem[2].ISCGPA;
              this.academic[2].ObtainedMarks =
                this.userExamDetailItem[2].ObtainedMarks;
              this.academic[2].FullMarks = this.userExamDetailItem[2].FullMarks;
              this.academic[2].PercentageMarks =
                this.userExamDetailItem[2].PercentageMarks;
              this.academic[2].YearofPassing =
                this.userExamDetailItem[2].YearofPassing;
              this.academic[2].College = this.userExamDetailItem[2].College;
              this.academic[2].Board = this.userExamDetailItem[2].Board;
              this.academic[2].CGPAScore = this.userExamDetailItem[2].CGPAScore;
            }

            if (response.data.Data.higherSecondaryDetail.length > 0) {
              this.userHighSecondaryDetail =
                response.data.Data.higherSecondaryDetail;

              this.applyFor[0].UserId = this.UserId;

              this.applyFor[0].PaperNo =
                this.userHighSecondaryDetail[0].PaperNo;
              this.preferenceItems1 = [];
              this.preferenceItems1.push(
                this.userHighSecondaryDetail[0].PaperNo
              );
              this.applyFor[0].Subject =
                this.userHighSecondaryDetail[0].Subject;
              this.applyFor[0].MarksObtainedTheory =
                this.userHighSecondaryDetail[0].MarksObtainedTheory;
              this.applyFor[0].MarksobtainedPractical =
                this.userHighSecondaryDetail[0].MarksobtainedPractical;
              this.applyFor[0].FullMarks =
                this.userHighSecondaryDetail[0].FullMarks;

              this.applyFor[1].UserId = this.UserId;
              this.applyFor[1].PaperNo =
                this.userHighSecondaryDetail[1].PaperNo;
              this.preferenceItems2 = [];
              this.preferenceItems2.push(
                this.userHighSecondaryDetail[1].PaperNo
              );
              this.applyFor[1].Subject =
                this.userHighSecondaryDetail[0].Subject;
              this.applyFor[1].MarksObtainedTheory =
                this.userHighSecondaryDetail[1].MarksObtainedTheory;
              this.applyFor[1].MarksobtainedPractical =
                this.userHighSecondaryDetail[1].MarksobtainedPractical;
              this.applyFor[1].FullMarks =
                this.userHighSecondaryDetail[1].FullMarks;

              this.applyFor[2].UserId = this.UserId;

              this.applyFor[2].PaperNo =
                this.userHighSecondaryDetail[2].PaperNo;
              this.preferenceItems3 = [];
              this.preferenceItems3.push(
                this.userHighSecondaryDetail[2].PaperNo
              );
              this.applyFor[2].Subject =
                this.userHighSecondaryDetail[2].Subject;
              this.applyFor[2].MarksObtainedTheory =
                this.userHighSecondaryDetail[2].MarksObtainedTheory;
              this.applyFor[2].MarksobtainedPractical =
                this.userHighSecondaryDetail[2].MarksobtainedPractical;
              this.applyFor[2].FullMarks =
                this.userHighSecondaryDetail[2].FullMarks;
            }

            if (response.data.Data.otherDetail != null) {
              this.userOtherDetailItem = response.data.Data.otherDetail;
              this.AgeprofImagepathName =
                this.userOtherDetailItem.AgeprofImagepath;
              this.LastExamName = this.userOtherDetailItem.LastExam;
              this.SignatureImagepathName =
                this.userOtherDetailItem.SignatureImagepath;

              this.AbcIdImagepathName = this.userOtherDetailItem.ABCIDDOC;

              this.photoName = this.userOtherDetailItem.photo;

              this.dobName = this.userOtherDetailItem.dob;
              this.XthMarksheetName = this.userOtherDetailItem.XthMarksheet;

              this.XIIthMarksheetName = this.userOtherDetailItem.XIIthMarksheet;
              this.GraduationMarksheetName =
                this.userOtherDetailItem.GraduationMarksheet;
              this.CategoryCertificateName =
                this.userOtherDetailItem.CategoryCertificate;
              this.University_Reg_CertificateName =
                this.userOtherDetailItem.University_Reg_Certificate;
              this.Physically_Disablied_CertificateName =
                this.userOtherDetailItem.Physically_Disablied_Certificate;
              this.EWS_CertificateName =
                this.userOtherDetailItem.EWS_Certificate;
            }
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;

          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },
    redirectToDownloadPage() {
      window.location.href =
        "http://103.181.109.178:8080/Download/servlet/DownloadServlet?reportPath=applicationFormMaster.jasper&id=" +
        this.UserId;
    },

    // #endregion
    updateDays() {
      if (this.Month && this.Year) {
        const selectedMonth = parseInt(this.Month);
        const selectedYear = parseInt(this.Year);

        const isLeapYear =
          (selectedYear % 4 === 0 && selectedYear % 100 !== 0) ||
          selectedYear % 400 === 0;
        const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();

        this.availableDays = this.DayItems.filter(
          (day) => parseInt(day) <= daysInMonth
        );

        if (selectedMonth === 2 && !isLeapYear) {
          this.availableDays = this.availableDays.filter(
            (day) => parseInt(day) !== 29
          );
        }
        this.Day = null;
      } else {
        this.availableDays = this.DayItems;
      }
    },

    isFullMarkndMarksObtainedDisabled(index) {
      const isCGPA = this.academic[index].ISCGPA;

      return isCGPA == "Yes";
    },
    isCGPAGradeScoreDisabled(index) {
      const isCGPA = this.academic[index].ISCGPA;

      return isCGPA == "No";
    },
    handleISCGPAChange(item) {
      if (item.ISCGPA === "Yes") {
        item.ObtainedMarks = null;
        item.FullMarks = null;
      }
      if (item.ISCGPA === "No") {
        item.PercentageMarks = null;
        item.CGPAScore = null;
      }
    },

    updateFullMarksValue(newAcademicData) {
      newAcademicData.forEach((items, index) => {
        if (
          index === newAcademicData.length - 1 &&
          items.Board === "University Of Gour Banga" &&
          (items.Examination === "LL.B - B.A, LL.B. (General)(5 years)" ||
            items.Examination === "LL.B - B.A, LL.B (Hons)(5 years)" ||
            items.Examination === "LL.B - 3 Years LL.B (Bachelor of Law)")
        ) {
          items.FullMarks = 4500;
        } else {
          if (items.Board === "University Of Gour Banga") {
            items.FullMarks = 800;
          }
          // else {
          //   items.FullMarks = null;
          // }
        }
      });
    },

    getSelectedExaminationItem(event, index) {
      if (index == 2) {
        this.getPreference(event);
      }
    },

    getPreference(examination) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.getPreference, {
        examination: examination,
      })
        .then((response) => {
          this.isLoaderActive = false;
          if (response.data.status.isSuccess == "true") {
            if (response.data.Data.length > 0) {
              // this.applyFor[0].PaperNo = response.data.Data[0].Preference1;

              //  this.applyFor[1].PaperNo = response.data.Data[0].Preference2;
              //   this.applyFor[2].PaperNo = response.data.Data[0].Preference3;
              this.preferenceItems1 = [];
              this.preferenceItems2 = [];
              this.preferenceItems3 = [];
              this.preferenceItems1.push(response.data.Data[0].Preference1);
              this.preferenceItems2.push(response.data.Data[0].Preference2);
              this.preferenceItems3.push(response.data.Data[0].Preference3);

              // this.applyFor[0].PaperNo = response.data.Data[0].Preference1;

              // this.applyFor[1].PaperNo = response.data.Data[0].Preference2;
              // this.applyFor[2].PaperNo = response.data.Data[0].Preference3;
              // by default item will not be selected
              this.applyFor[0].PaperNo = null;
              this.applyFor[1].PaperNo = null;
              this.applyFor[2].PaperNo = null;
            }
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },
  },
};
