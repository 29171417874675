export const ApiEndPoint = {
  // #region Authentication Api End Point
  Authentication: {
    login: "Login",
    logout: "logout",
    changePassword: "changePassword",
    forgotPassword: "ForgotPassword",
  },

  CreateUser: {
    createUser: "CreateUser",
    GetMaster: "GetMaster",
    FileUpload: "FileUpload",
    CreateCandidatePersonalDetail: "CreateCandidatePersonalDetail",
    CreateCandidateContactDetail: "CreateCandidateContactDetail",
    CreateCandidateExamDetail: "CreateCandidateExamDetail",
    GetApplyFor: "GetApplyFor",
    GetApplyForOtherDetails: "GetApplyForOtherDetails",
    CreateCandidateHigherSecondaryDetail:
      "CreateCandidateHigherSecondaryDetail",
    CreateCandidateOtherDetail: "CreateCandidateOtherDetail",
    getCandidateDetail: "GetCandidateDetail",
    getPreference: "GetPreference",
  },
  OFOC: {
    GetOfoc: "GetOfoc",
    UpdateOfoc: "UpdateOfoc",
  },

  OnlinePayment: {
    GetPayment: "GetPayment",
  },
  Grievance: {
    postGrievance: "PostGrievance",
    getGrievance: "GetGrievance",
    getGrievanceAdmin: "GetGrievanceAdmin",
    updateGrievance: "UpdateGrievance",
  },

  AdmissionCancellation: {
    postAdmissionCancellation: "PostAdmissionCancellation",
    getAdmissionCancellation: "GetAdmissionCancellation",
  },

  // #endregion

  //#region  User End point
  User: {
    getUser: "users",
    saveUser: "save",
  },
  //#endregion

  //#region  City End point
  City: {
    getCity: "getCity",
    saveCity: "saveCity",
    updateCity: "updateCity",
    changeCityStatus: "changeCityStatus",
    deleteCity: "deleteCity",
  },
  //#endregion

  //#region  Area End point
  Area: {
    getArea: "getArea",
    saveArea: "saveArea",
    updateArea: "updateArea",
    changeAreaStatus: "changeAreaStatus",
    deleteArea: "deleteArea",
  },
  //#endregion

  //#region  Docotr
  Doctor: {
    getDoctorDetails: "getDoctorDetails",
  },
  //#endregion

  //#region Clinic
  Clinic: {
    getClinicDetails: "getClinicDetails",
  },
  //#endregion

  //#region  Competition End point
  CompetitionType: {
    getCompetitionActivityWithoutPagination:
      "getCompetitionActivityWithoutPagination",
    getCompetitionCategoryWithoutPagination:
      "getCompetitionCategoryWithoutPagination",
    getCompetitionType: "getCompetitionType",
    saveCompetitionType: "saveCompetitionType",
    updateCompetitionType: "updateCompetitionType",
    changeCompetitionTypeStatus: "changeCompetitionTypeStatus",
    deleteCompetitionType: "deleteCompetitionType",
  },
  //#endregion
  //#region  Competition Master End point
  CompetitionMaster: {
    getCompetitionActivityWithoutPagination:
      "getCompetitionActivityWithoutPagination",
    getCompetitionCategoryWithoutPagination:
      "getCompetitionCategoryWithoutPagination",
    getCompetitionTypeWithoutPagination: "getCompetitionTypeWithoutPagination",
    getWeekdayWithoutPagination: "getWeekdayWithoutPagination",
    getCompetitionMaster: "getCompetitionMaster",
    saveCompetitionMaster: "saveCompetitionMaster",
    updateCompetitionMaster: "updateCompetitionMaster",
    changeCompetitionMasterStatus: "changeCompetitionMasterStatus",
    deleteCompetitionMaster: "deleteCompetitionMaster",
  },
  //#endregion

  //#region  Area End point
  BookingDetails: {
    getBookingDetails: "getBookingDetails",
    clinicWiseBooking: "clinicWiseBooking",
    upcomingBookingDetails: "upcomingBookingDetails",
  },
  //#endregion
};
