<template>
  <v-app id="inspire">
    <div></div>
    <v-overlay :value="isLoaderActive" color="primary">
      <v-progress-circular
        indeterminate
        size="50"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-main>
      <v-container
        class="fill-height"
        fluid
        :style="{
          'background-image': 'url(https://logicnmind.com/gimages/bg_1.jpg)',
        }"
      >
        <v-row align="center" justify="center" class="mt-0 pt-0">
          <v-card color="white" width="100%" height="130">
            <v-row align="center" justify="center">
              <v-col cols="12" sm="12" md="1">
                <v-img
                  :src="loginLogo"
                  height="100"
                  width="100"
                  class="mx-2 my-3"
                ></v-img>
              </v-col>
              <v-col cols="12" sm="12" md="5" class="p-0 m-0">
                <h1 style="color: #642bb8">University of Gour Banga</h1>
              </v-col>
              <v-col cols="12" sm="12" md="5" class="p-0 m-0">
                <strong style="color: red"
                  >Form Fill up dates<br />
                  Start Date - 3rd September,2024<br />
                  End Date - 17th September,2024</strong
                >
              </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <div class="text-center">
                <v-chip
                  class="ma-2 text-primary"
                  color="white"
                  @click="gotoToNotification"
                  text-color="purple"
                >
                  <strong>Notification</strong>
                </v-chip>
                <v-chip
                  class="ma-2 text-primary"
                  color="white"
                  @click="gotoToEWS"
                  text-color="purple"
                >
                  <strong>EWS Notice</strong>
                </v-chip>
                <v-chip
                  class="ma-2 text-primary"
                  color="white"
                  @click="gotoToKeyDates"
                  text-color="purple"
                >
                  <strong> Key Dates</strong>
                </v-chip>

                <v-chip
                  class="ma-2 text-primary"
                  color="white"
                  @click="gotoToFeesStructure"
                  text-color="purple"
                >
                  <strong> Fees Structure</strong>
                </v-chip>

                <v-chip
                  class="ma-2 text-primary"
                  color="white"
                  @click="gotoToSeatMatrix"
                  text-color="purple"
                >
                  <strong> Seat Matrix</strong>
                </v-chip>

                <v-chip
                  class="ma-2 text-primary"
                  color="white"
                  @click="gotoToCourseOffered"
                  text-color="purple"
                >
                  <strong> Course Offered</strong>
                </v-chip>

                <v-chip
                  class="ma-2 text-primary"
                  color="white"
                  @click="gotoToInstruction"
                  text-color="purple"
                >
                  <strong>Instruction</strong>
                </v-chip>

                <v-chip
                  class="ma-2 text-primary"
                  color="primary"
                  @click="gotoToPML"
                  text-color="white"
                >
                  <strong>Merit List</strong>
                </v-chip>

                <v-chip
                  class="ma-2 text-primary"
                  color="primary"
                  @click="gotoToOFOC"
                  text-color="white"
                >
                  <strong>OFOC List</strong>
                </v-chip>

                <v-chip
                  class="ma-2 text-primary"
                  color="primary"
                  @click="gotoToAdmission"
                  text-color="white"
                >
                  <strong>Admission List</strong>
                </v-chip>

                <v-chip
                  class="ma-2 text-primary"
                  color="primary"
                  @click="gotoToAbcId"
                  text-color="white"
                >
                  <strong>CREATE ABC ID</strong>
                </v-chip>
              </div>
            </v-row>
          </v-card>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" md="5" class="mt-6">
            <v-card class="mx-auto" color="white">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title> Existing user? </v-toolbar-title>

                <v-spacer></v-spacer>
                <div>Sign in your application no and password below.</div>
              </v-toolbar>

              <v-card-text>
                <v-form
                  ref="holdingForm"
                  v-model="isholdingFormValid"
                  lazy-validation
                >
                  <v-text-field
                    label="Enter Application ID"
                    prepend-inner-icon="mdi-clipboard-account"
                    outlined
                    clearable
                    autofocus
                    type="text"
                    color="primary"
                    v-model="mobile"
                  ></v-text-field>

                  <v-text-field
                    label="Password"
                    prepend-inner-icon="mdi-lock"
                    outlined
                    clearable
                    color="primary"
                    v-model="password"
                    :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    @click:append="isPasswordVisible = !isPasswordVisible"
                    @keyup.enter="validateLogin"
                    :rules="validationRulesRequired"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-col>
                  <v-btn text small color="primary" @click="newApplicant"
                    >New Applicant? Create a new Account</v-btn
                  >
                  <v-btn text small color="primary" @click="addDialog = true"
                    >Forgot Password</v-btn
                  >
                </v-col>

                <v-divider></v-divider>

                <div class="text-center">
                  <v-btn
                    class="ma-2"
                    filled
                    color="primary"
                    :disabled="!isholdingFormValid"
                    @click="validateLogin"
                  >
                    Login</v-btn
                  >
                </div>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <div>
            <v-alert dense type="error">
              Candidates are requested to meticulously follow the UGB PG
              Admission Website on a regular basis for any update or important
              announcements. University Authority and the Agency will not be
              liable for nondelivery of SMS and or EMAIL due to any network
              issues/ technical failure from the end of the service provider or
              any prohibition from the Telecom Regulatory Authority of India
              (TRAI) .
            </v-alert>
          </div>
        </v-row>
        <!-- start -->
        <v-row>
          <v-dialog
            transition="dialog-top-transition"
            v-model="addDialog"
            max-width="800"
            scrollable
            :fullscreen="$vuetify.breakpoint.smAndDown"
            persistent
          >
            <template v-slot:default="dialog">
              <v-overlay :value="isDialogLoaderActive" color="primary">
                <v-progress-circular
                  indeterminate
                  size="50"
                  color="primary"
                ></v-progress-circular>
              </v-overlay>
              <v-card class="mx-auto" color="white">
                <v-toolbar
                  color="primary"
                  dark
                  :max-height="$vuetify.breakpoint.smAndDown ? 56 : '20vh'"
                >
                  <v-toolbar-title class="popup-header">
                    Forgot Password</v-toolbar-title
                  >
                  <v-spacer></v-spacer><v-spacer></v-spacer>
                  <v-btn icon dark @click="addDialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text class="py-4 px-2">
                  <v-form
                    ref="holdingFormAdd"
                    v-model="isFormAddValid"
                    lazy-validation
                  >
                    <v-row class="mx-auto d-flex">
                      <v-col cols="12" md="12" class="pt-5 pb-2">
                        <v-text-field
                          label="Email ID"
                          prepend-inner-icon="mdi-email"
                          regular
                          clearable
                          type="text"
                          color="primary"
                          v-model="email"
                          :rules="validationRules_email"
                          maxlength="100"
                          counter="100"
                        ></v-text-field>
                        <v-text-field
                          label="Mobile Number"
                          prepend-inner-icon="mdi-cellphone"
                          regular
                          clearable
                          type="text"
                          color="primary"
                          v-model="Fmobile"
                          :rules="validationRulesMobile"
                          @keypress="acceptDigit"
                          maxlength="10"
                          counter="10"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-end pt-4 pb-6">
                  <v-btn class="mx-2" @click="addDialog = false">Close</v-btn>
                  <v-btn
                    class="mx-2"
                    @click="forgotPassword"
                    color="primary"
                    :disabled="!isFormAddValid"
                  >
                    Submit
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-row>
        <!--end of add-->
      </v-container>
    </v-main>

    <v-footer color="primary" padless>
      <v-row justify="center" no-gutters>
        <v-col class="primary py-4 text-center white--text" cols="12">
          <!-- {{ new Date().getFullYear() }} —  -->

          <strong>{{ companyName }}</strong
          ><br />
          {{ address }}<br />
          <small class="mr-4">{{ email }}</small> -
          <small class="ml-4"> {{ phone }}</small>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { login } from "../login/login";
export default login;
</script>
