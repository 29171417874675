<template>
  <!-- Card Start -->
  <v-container fluid class="pa-4">
    <v-overlay :value="isLoaderActive" color="primary">
      <v-progress-circular
        indeterminate
        size="50"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-card class="mb-10">
      <v-row class="ml-4 mr-4 mt-1 mb-4">
        <v-toolbar-title dark color="primary">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5">
                <strong>{{ entity }}</strong>
              </v-list-item-title>
              <v-list-item-subtitle
                >Home <v-icon>mdi-chevron-right</v-icon> Application
                <v-icon>mdi-chevron-right</v-icon>
                {{ entity }}
              </v-list-item-subtitle>
              <v-list-item-subtitle> </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn
          small
          elevation="2"
          color="primary"
          class="mt-8"
          v-if="userOtherDetailItem != null || isSubmitClicked == true"
          @click="redirectToDownloadPage"
          >Download Application</v-btn
        ><br />
        <strong style="color: red" class="mt-8"
          >( *) Indicates mandatory fields</strong
        >
      </v-row>
      <div class="mx-4">
        <v-alert
          v-model="alert"
          border="left"
          close-text="Close Alert"
          color="deep-purple accent-4"
          dark
        >
          ABC (ACADEMIC BANK OF CREDIT) ID: According to the directives of UGC
          (University Grants Commission), all the students must have their valid
          ABC ID for submission of Application Form for admission. Therefore,
          all are requested to obtain their ABC ID before filling in the Online
          Application Form. For details, please visit https://www.abc.gov.in
        </v-alert>
      </div>

      <transition name="fade" mode="out-in">
        <v-stepper v-model="e6" vertical>
          <v-stepper-step :complete="e6 > 1" step="1">
            Personal Information <v-spacer></v-spacer>
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-form
              ref="holdingForm"
              v-model="isholdingFormValid"
              lazy-validation
            >
              <v-card color="grey lighten-4" class="mb-12" height="auto">
                <v-row class="mx-2 mt-2" dense>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="Locality"
                      :items="LocalityItems"
                      filled
                      chips
                      small-chips
                      label="Student Type"
                      :rules="validationRulesRequired"
                      @change="filterExaminationItems"
                    >
                      <template #label
                        >Student Type<span class="red--text"
                          ><strong> *</strong></span
                        ></template
                      >
                    </v-autocomplete>
                    <span class="red--text">
                      * Candidate is to be treated as Home University Student if
                      he/she passed from University of Gour Banga and didn't
                      take any Migration Certficate from University of Gour
                      Banga.
                    </span>
                  </v-col>
                </v-row>

                <v-row class="mx-2" dense>
                  <v-col cols="12" md="4" SM="12">
                    <v-text-field
                      label="Registration Number"
                      filled
                      :rules="validationRulesRequired"
                      v-model="RegistrationNumber"
                      maxlength="25"
                      counter="25"
                    >
                      <template #label
                        >Registration Number<span class="red--text"
                          ><strong> *</strong></span
                        ></template
                      >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" SM="12">
                    <v-text-field
                      label="Registration Session"
                      filled
                      :rules="validationRulesRequired"
                      v-model="RegistrationSession"
                      maxlength="12"
                      counter="12"
                    >
                      <template #label
                        >Registration Session<span class="red--text"
                          ><strong> *</strong></span
                        ></template
                      >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" SM="12">
                    <v-autocomplete
                      v-model="Migrationcertificate"
                      :items="MigrationItems"
                      filled
                      chips
                      small-chips
                      label="Migration Certificate"
                      :rules="validationRulesRequired"
                    >
                      <template #label
                        >Migration Certificate<span class="red--text"
                          ><strong> *</strong></span
                        ></template
                      >
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row class="mx-2 mt-2" dense>
                  <v-col cols="12" md="4" SM="12">
                    <v-text-field
                      label="First Name"
                      filled
                      :rules="validationRules_max_25"
                      max-length="25"
                      v-model="FName"
                      maxlength="25"
                      @keypress="acceptCharacterWithSpace"
                      counter="25"
                    >
                      <template #label
                        >First Name<span class="red--text"
                          ><strong> *</strong></span
                        ></template
                      >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" SM="12">
                    <v-text-field
                      label="Middle Name"
                      filled
                      :rules="validationRules_max_25_without_required"
                      v-model="MName"
                      maxlength="25"
                      @keypress="acceptCharacterWithSpace"
                      counter="25"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" SM="12">
                    <v-text-field
                      label="Last Name"
                      filled
                      :rules="validationRules_max_25"
                      v-model="LName"
                      maxlength="25"
                      @keypress="acceptCharacterWithSpace"
                      counter="25"
                    >
                      <template #label
                        >Last Name<span class="red--text"
                          ><strong> *</strong></span
                        ></template
                      >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mx-2" dense>
                  <v-col cols="12" md="4" SM="12">
                    <v-text-field
                      label="Email Id"
                      filled
                      :rules="validationRules_email"
                      v-model="EmailID"
                      maxlength="100"
                      counter="100"
                    >
                      <template #label
                        >Email Id<span class="red--text"
                          ><strong> *</strong></span
                        ></template
                      >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" SM="12">
                    <v-text-field
                      label="Aadhaar Number"
                      filled
                      v-model="AdhaarNO"
                      :rules="validationRules_aadhaar"
                    >
                      <template #label
                        >Aadhaar Number(XXXX XXXX XXXX)<span class="red--text"
                          ><strong> *</strong></span
                        ></template
                      >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" SM="12">
                    <v-text-field
                      label="ABC ID"
                      filled
                      v-model="ABCId"
                      :rules="validationRules_optionalAbcdId"
                      maxlength="12"
                      counter="12"
                      @keypress="acceptDigit"
                    >
                      <template #label>ABC ID </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mx-2" dense>
                  <v-col cols="12" md="4" SM="12">
                    <v-text-field
                      label="Mother's Name"
                      filled
                      :rules="validationRulesRequired"
                      v-model="MotherNAME"
                      maxlength="50"
                      @keypress="acceptCharacterWithSpace"
                      counter="50"
                    >
                      <template #label
                        >Mother's Name<span class="red--text"
                          ><strong> *</strong></span
                        ></template
                      >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" SM="12">
                    <v-text-field
                      label="Father's Name"
                      filled
                      :rules="validationRulesRequired"
                      v-model="FatherName"
                      maxlength="50"
                      @keypress="acceptCharacterWithSpace"
                      counter="50"
                    >
                      <template #label
                        >Father's Name<span class="red--text"
                          ><strong> *</strong></span
                        ></template
                      >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mx-2 mt-2" dense>
                  <v-col cols="12" md="4" SM="12">
                    <v-text-field
                      label="Mobile Number"
                      filled
                      :rules="validationRulesRequired"
                      v-model="MobileNumber"
                      @keypress="acceptDigit"
                      maxlength="10"
                      counter="10"
                    >
                      <template #label
                        >Mobile Number<span class="red--text"
                          ><strong> *</strong></span
                        ></template
                      >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" SM="12">
                    <v-text-field
                      label="Alternate Mobile Number"
                      v-model="AlterNateMobileNo"
                      filled
                      :rules="validationRulesRequired"
                      @keypress="acceptDigit"
                      maxlength="10"
                      counter="10"
                    >
                      <template #label
                        >Alternate Mobile Number<span class="red--text"
                          ><strong> *</strong></span
                        ></template
                      >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" SM="12">
                    <v-autocomplete
                      v-model="MotherOccupation"
                      :items="OccupationItems"
                      filled
                      chips
                      small-chips
                      label="Mother's Occupations"
                      :rules="validationRulesRequired"
                    >
                      <template #label
                        >Mother's Occupations<span class="red--text"
                          ><strong> *</strong></span
                        ></template
                      >
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row class="mx-2 mt-2" dense>
                  <v-col cols="12" md="4" SM="12">
                    <v-autocomplete
                      v-model="FatherOccupation"
                      :items="OccupationItems"
                      filled
                      chips
                      small-chips
                      label="Father's Occupations"
                      :rules="validationRulesRequired"
                    >
                      <template #label
                        >Father's Occupations<span class="red--text"
                          ><strong> *</strong></span
                        ></template
                      >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4" SM="12">
                    <v-text-field
                      label="Annual Family Income"
                      filled
                      :rules="validationRulesRequired"
                      v-model="IncomePerMonth"
                      @keypress="acceptDigit"
                    >
                      <template #label
                        >Annual Family Income<span class="red--text"
                          ><strong> *</strong></span
                        ></template
                      >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mx-2 mt-2" dense>
                  <v-col cols="12" md="4" SM="12">
                    <v-row dense>
                      <v-col cols="12" md="12" SM="12">
                        <span>Date of Birth</span>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" md="4" SM="12">
                        <v-autocomplete
                          v-model="Month"
                          :items="MonthItems"
                          filled
                          chips
                          small-chips
                          label="Month"
                          :rules="validationRulesRequired"
                          @change="updateDays"
                        >
                          <template #label
                            >Month<span class="red--text"
                              ><strong> *</strong></span
                            ></template
                          >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="4" SM="12">
                        <v-autocomplete
                          v-model="Year"
                          :items="YearItems"
                          filled
                          chips
                          small-chips
                          label="Year"
                          :rules="validationRulesRequired"
                          @change="updateDays"
                        >
                          <template #label
                            >Year<span class="red--text"
                              ><strong> *</strong></span
                            ></template
                          >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="4" SM="12">
                        <v-autocomplete
                          v-model="Day"
                          :items="availableDays"
                          filled
                          chips
                          small-chips
                          label="Day"
                          :rules="validationRulesRequired"
                        >
                          <template #label
                            >Day<span class="red--text"
                              ><strong> *</strong></span
                            ></template
                          >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="8" SM="12">
                    <v-row dense>
                      <v-col cols="12" md="12" SM="12">
                        <span>&nbsp;</span>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" md="3" SM="12">
                        <v-autocomplete
                          v-model="Gender"
                          :items="GenderItems"
                          filled
                          chips
                          small-chips
                          label="Gender"
                          :rules="validationRulesRequired"
                        >
                          <template #label
                            >Gender<span class="red--text"
                              ><strong> *</strong></span
                            ></template
                          >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="3" SM="12">
                        <v-autocomplete
                          v-model="Nationality"
                          :items="NationalityItems"
                          filled
                          chips
                          small-chips
                          label="Nationality"
                          :rules="validationRulesRequired"
                        >
                          <template #label
                            >Nationality<span class="red--text"
                              ><strong> *</strong></span
                            ></template
                          >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="3" SM="12">
                        <v-autocomplete
                          v-model="Religion"
                          :items="ReligionItems"
                          filled
                          chips
                          small-chips
                          label="Religion"
                          :rules="validationRulesRequired"
                        >
                          <template #label
                            >Religion<span class="red--text"
                              ><strong> *</strong></span
                            ></template
                          >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="3" SM="12">
                        <v-autocomplete
                          v-model="Category"
                          :items="CategoryItems"
                          filled
                          chips
                          small-chips
                          label="Category"
                          :rules="validationRulesRequired"
                        >
                          <template #label
                            >Category<span class="red--text"
                              ><strong> *</strong></span
                            ></template
                          >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row dense class="mx-2 mt-2">
                  <v-col cols="12" md="3" SM="12">
                    <v-autocomplete
                      v-model="MaterialStatus"
                      :items="MaterialStatusItems"
                      filled
                      chips
                      small-chips
                      label="Nationality"
                      :rules="validationRulesRequired"
                    >
                      <template #label
                        >Marital Status
                        <span class="red--text"
                          ><strong> *</strong></span
                        ></template
                      >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3" SM="12">
                    <v-autocomplete
                      v-model="EmploymentStatus"
                      :items="EmploymentStatusItems"
                      filled
                      chips
                      small-chips
                      label="Religion"
                      :rules="validationRulesRequired"
                    >
                      <template #label
                        >Employment Status<span class="red--text"
                          ><strong> *</strong></span
                        ></template
                      >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3" SM="12">
                    <v-autocomplete
                      v-model="BloodGroup"
                      :items="BloodGroupItems"
                      filled
                      chips
                      small-chips
                      label="Category"
                      :rules="validationRulesRequired"
                    >
                      <template #label
                        >Blood Group<span class="red--text"
                          ><strong> *</strong></span
                        ></template
                      >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3" SM="12">
                    <v-autocomplete
                      v-model="PWD"
                      :items="PWDItems"
                      filled
                      chips
                      small-chips
                      label="PWD/PH"
                      :rules="validationRulesRequired"
                    >
                      <template #label
                        >PWD/PH
                        <span class="red--text"
                          ><strong> *</strong></span
                        ></template
                      >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row dense class="mx-2 mt-2">
                  <v-col cols="12" md="3" SM="12" v-if="PWD == 'Yes'">
                    <v-autocomplete
                      v-model="PHQuotaType"
                      :items="PHQuotaTypeItems"
                      filled
                      chips
                      small-chips
                      label="PH Quota Type"
                      :rules="validationRulesRequired"
                    >
                      <template #label
                        >PH Quota Type
                        <span class="red--text"
                          ><strong> *</strong></span
                        ></template
                      >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3" SM="12">
                    <v-autocomplete
                      v-model="EWS"
                      :items="EWSItems"
                      filled
                      chips
                      small-chips
                      label="EWS"
                      :rules="validationRulesRequired"
                      v-if="Category == 'Unreserved(UR)'"
                    >
                      <template #label
                        >EWS<span class="red--text"
                          ><strong> *</strong></span
                        ></template
                      >
                    </v-autocomplete>
                    <span class="red--text" v-if="Category == 'Unreserved(UR)'">
                      * The benefit of 10% reservation under EWS category can
                      only be availed upon production of an EWS Certificate
                      issued by the competent authorities in the prescribed
                      format.
                    </span>
                  </v-col>
                </v-row>
                <v-row class="mx-2 mt-2" dense>
                  <v-col cols="12" md="4" SM="12">
                    <v-text-field
                      label="GuardianName"
                      filled
                      :rules="validationRulesRequired"
                      v-model="GuardianName"
                      maxlength="50"
                      @keypress="acceptCharacterWithSpace"
                      counter="50"
                    >
                      <template #label
                        >Guardian Name<span class="red--text"
                          ><strong> *</strong></span
                        ></template
                      >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" SM="12">
                    <v-text-field
                      label="GuardiaContactNo"
                      filled
                      :rules="validationRulesRequired"
                      v-model="GuardiaContactNo"
                      @keypress="acceptDigit"
                      maxlength="10"
                      counter="10"
                    >
                      <template #label
                        >Guardian Contact No<span class="red--text"
                          ><strong> *</strong></span
                        ></template
                      >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" SM="12">
                    <v-text-field
                      label="Guardian Email"
                      filled
                      :rules="validationRules_optionalemail"
                      v-model="GuardiaEmail"
                      maxlength="100"
                      counter="100"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" SM="12">
                    <v-text-field
                      label="Relation With Guardian"
                      filled
                      :rules="validationRulesRequired"
                      v-model="RelationWithGuardian"
                    >
                      <template #label
                        >Relation With Guardian<span class="red--text"
                          ><strong> *</strong></span
                        ></template
                      >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card>
              <v-btn color="primary" @click="savePersonalDetails"> Next </v-btn>
              <v-btn text> Cancel </v-btn>
            </v-form>
          </v-stepper-content>

          <v-stepper-step :complete="e6 > 2" step="2"> Address </v-stepper-step>

          <v-stepper-content step="2">
            <v-form
              ref="holdingFormAddress"
              v-model="isholdingFormValid"
              lazy-validation
            >
              <v-card color="grey lighten-4" class="mb-12" height="auto">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="6" SM="12">
                      <v-row align="center" class="mx-0">
                        <v-card-title>Present Address</v-card-title>
                      </v-row>
                      <v-row class="mx-2 mt-2" dense>
                        <v-col cols="12" md="12" SM="12">
                          <v-text-field
                            filled
                            :rules="validationRulesRequired"
                            v-model="PresentAddressLIne1"
                            maxlength="100"
                            counter="100"
                          >
                            <template #label
                              >Address Line 1
                              <span class="red--text"
                                ><strong> *</strong></span
                              ></template
                            >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="12" SM="12">
                          <v-text-field
                            label="Address Line 2"
                            filled
                            v-model="PresentAddressLine2"
                            maxlength="100"
                            counter="100"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="12" SM="12">
                          <v-text-field
                            filled
                            :rules="validationRulesRequired"
                            v-model="PresentDistrict"
                            maxlength="50"
                            counter="50"
                          >
                            <template #label
                              >District<span class="red--text"
                                ><strong> *</strong></span
                              ></template
                            >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="12" SM="12">
                          <v-autocomplete
                            v-model="PresentCountry"
                            :items="PresentCountryItems"
                            filled
                            chips
                            small-chips
                            :rules="validationRulesRequired"
                          >
                            <template #label
                              >Country<span class="red--text"
                                ><strong> *</strong></span
                              ></template
                            >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="12" SM="12">
                          <v-autocomplete
                            v-model="PresentState"
                            :items="PresentStateItems"
                            filled
                            chips
                            small-chips
                            :rules="validationRulesRequired"
                          >
                            <template #label
                              >State<span class="red--text"
                                ><strong> *</strong></span
                              ></template
                            >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="12" SM="12">
                          <v-text-field
                            filled
                            :rules="validationRulesRequired"
                            v-model="PresentPIN"
                            maxlength="6"
                            counter="6"
                            @keypress="acceptDigit"
                          >
                            <template #label
                              >PIN<span class="red--text"
                                ><strong> *</strong></span
                              ></template
                            >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="12" SM="12">
                          <v-text-field
                            filled
                            v-model="PO"
                            :rules="validationRulesRequired"
                            label="Post Office"
                            maxlength="50"
                            counter="50"
                          >
                            <template #label
                              >Post Office<span class="red--text"
                                ><strong> *</strong></span
                              ></template
                            >
                          </v-text-field>
                        </v-col>

                        <v-col cols="12" md="12" SM="12">
                          <v-text-field
                            filled
                            v-model="PS"
                            label="Police Station"
                            :rules="validationRulesRequired"
                            maxlength="50"
                            counter="50"
                          >
                            <template #label
                              >Police Station<span class="red--text"
                                ><strong> *</strong></span
                              ></template
                            >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6" SM="12">
                      <v-row align="center" class="mx-0">
                        <v-card-title>Permanent Address</v-card-title>
                        <v-checkbox
                          v-model="chlSameAddresss"
                          label="Same as present"
                          @click="sameAddress"
                        ></v-checkbox>
                      </v-row>
                      <v-row class="mx-2 mt-2" dense>
                        <v-col cols="12" md="12" SM="12">
                          <v-text-field
                            filled
                            :rules="validationRulesRequired"
                            v-model="PermanentddressLIne1"
                            maxlength="100"
                            counter="100"
                          >
                            <template #label
                              >Address Line 1
                              <span class="red--text"
                                ><strong> *</strong></span
                              ></template
                            >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="12" SM="12">
                          <v-text-field
                            label="Address Line 2"
                            filled
                            v-model="PermanentAddressLine2"
                            maxlength="100"
                            counter="100"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="12" SM="12">
                          <v-text-field
                            filled
                            :rules="validationRulesRequired"
                            v-model="PermanentDistrict"
                            maxlength="50"
                            counter="50"
                          >
                            <template #label
                              >District<span class="red--text"
                                ><strong> *</strong></span
                              ></template
                            >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="12" SM="12">
                          <v-autocomplete
                            v-model="PermanentCountry"
                            :items="PermanentCountryItems"
                            filled
                            chips
                            small-chips
                            :rules="validationRulesRequired"
                          >
                            <template #label
                              >Country<span class="red--text"
                                ><strong> *</strong></span
                              ></template
                            >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="12" SM="12">
                          <v-autocomplete
                            v-model="PermanentState"
                            :items="PermanentStateItems"
                            filled
                            chips
                            small-chips
                            :rules="validationRulesRequired"
                          >
                            <template #label
                              >State<span class="red--text"
                                ><strong> *</strong></span
                              ></template
                            >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="12" SM="12">
                          <v-text-field
                            filled
                            :rules="validationRulesRequired"
                            v-model="PermanentPIN"
                            maxlength="6"
                            counter="6"
                            @keypress="acceptDigit"
                          >
                            <template #label
                              >PIN<span class="red--text"
                                ><strong> *</strong></span
                              ></template
                            >
                          </v-text-field>
                        </v-col>

                        <v-col cols="12" md="12" SM="12">
                          <v-text-field
                            filled
                            v-model="PermanentPO"
                            :rules="validationRulesRequired"
                            label="Post Office"
                            maxlength="50"
                            counter="50"
                          >
                            <template #label
                              >Post Office<span class="red--text"
                                ><strong> *</strong></span
                              ></template
                            >
                          </v-text-field>
                        </v-col>

                        <v-col cols="12" md="12" SM="12">
                          <v-text-field
                            filled
                            v-model="PermanentPS"
                            label="Police Station"
                            :rules="validationRulesRequired"
                            maxlength="50"
                            counter="50"
                          >
                            <template #label
                              >Police Station<span class="red--text"
                                ><strong> *</strong></span
                              ></template
                            >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-btn color="primary" @click="saveAddressDetails"> Next </v-btn>
              <v-btn color="primary" @click="e6 = 1" class="ml-2">
                Previous
              </v-btn>
            </v-form>
          </v-stepper-content>

          <v-stepper-step :complete="e6 > 3" step="3">
            Academic Details
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-form
              ref="holdingFormAcademic"
              v-model="isholdingFormValid"
              lazy-validation
            >
              <v-card color="grey lighten-4" class="mb-12" height="auto">
                <span class="red--text"
                  ><strong
                    >If CGPA then student should mention CGPA Grade
                    point</strong
                  ></span
                >

                <v-simple-table class="p-1 m-1">
                  <thead>
                    <tr class="p-0">
                      <th class="text-center">Examination</th>
                      <th class="text-center">Year of Passing</th>
                      <th class="text-center">Board/University</th>
                      <th class="text-center">Is CGPA(under CBCS)?</th>
                      <th class="text-center">
                        Obtained
                        Marks&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </th>
                      <th class="text-center">
                        Full
                        Marks&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </th>
                      <th class="text-center" v-if="false">
                        CGPA Grade&nbsp;&nbsp;&nbsp;
                      </th>
                      <th class="text-center">CGPA Score&nbsp;&nbsp;&nbsp;</th>
                      <th class="text-center" v-if="false">Div/Class</th>
                      <!-- <th class="text-center">Passesd/Appeared</th>
                      <th class="text-center">College/School</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(items, index) in academic" :key="index">
                      <td>
                        <v-autocomplete
                          v-model="items.Examination"
                          :items="
                            index == '0'
                              ? secondaryExamItem
                              : index == '1'
                              ? higherSecondaryExamItem
                              : ExaminationItems
                          "
                          filled
                          chips
                          small-chips
                          @change="getSelectedExaminationItem($event, index)"
                          :rules="validationRulesRequired"
                        >
                        </v-autocomplete>
                      </td>
                      <td>
                        <v-autocomplete
                          v-model="items.YearofPassing"
                          :items="
                            index == '0'
                              ? secondaryYearItem
                              : index == '1'
                              ? higherSecondaryYearItem
                              : YearofPassingItems
                          "
                          filled
                          chips
                          small-chips
                          :rules="validationRulesRequired"
                        >
                        </v-autocomplete>
                      </td>
                      <td>
                        <v-autocomplete
                          v-model="items.Board"
                          :items="
                            index == '0'
                              ? secondaryBoardItem
                              : index == '1'
                              ? higherSecondaryBoardItem
                              : BoardItems
                          "
                          filled
                          chips
                          small-chips
                          :rules="validationRulesRequired"
                        >
                        </v-autocomplete>
                      </td>
                      <td>
                        <v-autocomplete
                          v-model="items.ISCGPA"
                          :items="
                            index == 0 || index == 1 ? ['No'] : ISCGPAItems
                          "
                          filled
                          chips
                          small-chips
                          :rules="validationRulesRequired"
                          @change="handleISCGPAChange(items)"
                        >
                        </v-autocomplete>
                      </td>
                      <td>
                        <v-text-field
                          filled
                          v-model="items.ObtainedMarks"
                          :disabled="isFullMarkndMarksObtainedDisabled(index)"
                          :rules="
                            items.ISCGPA === 'No' ? validationRulesRequired : []
                          "
                          @keypress="acceptDigit"
                        >
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          filled
                          v-model="items.FullMarks"
                          :disabled="isFullMarkndMarksObtainedDisabled(index)"
                          :rules="
                            items.ISCGPA === 'No' ? validationRulesRequired : []
                          "
                          @keypress="acceptDigit"
                        >
                        </v-text-field>
                      </td>
                      <td v-if="false">
                        <v-text-field
                          filled
                          v-model="items.PercentageMarks"
                          :disabled="isCGPAGradeScoreDisabled(index)"
                          :rules="
                            items.ISCGPA === 'Yes'
                              ? validationRulesRequired
                              : []
                          "
                        >
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          filled
                          v-model="items.CGPAScore"
                          :rules="
                            items.ISCGPA === 'Yes' ? validationRules_max10 : []
                          "
                          style="width: 80px"
                          :disabled="isCGPAGradeScoreDisabled(index)"
                        >
                        </v-text-field>
                      </td>
                      <td v-if="false">
                        <v-autocomplete
                          v-model="items.Division"
                          :items="DivisionItems"
                          filled
                          chips
                          small-chips
                          :rules="validationRulesRequired"
                        >
                        </v-autocomplete>
                      </td>

                      <!-- <td>
                        <v-autocomplete
                          v-model="items.Status"
                          :items="StatusItems"
                          filled
                          chips
                          small-chips
                        >
                        </v-autocomplete>
                      </td>

                      <td>
                        <v-text-field filled v-model="items.College">
                        </v-text-field>
                      </td> -->
                    </tr>
                    <tr>
                      <td colspan="10">
                        <span class="red--text">
                          * Full marks for LLB Course is 4500 and others subject
                          full marks course for Honours Course is 800 for
                          University of Gour Banga student (1+1+1 non CBCS
                          System)</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td colspan="10">
                        <span class="red--text">
                          ** In case of CGPA (Only for Gradation) the candidates
                          need to put the CGPA Grade Point only. Nothing should
                          be mentioned regarding marks obtained and total
                          marks.</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
              <v-btn color="primary" @click="saveAcademicDetails"> Next </v-btn>
              <v-btn color="primary" @click="e6 = 2" class="mx-2">
                Previous
              </v-btn>
            </v-form>
          </v-stepper-content>

          <v-stepper-step step="4" :complete="e6 > 4">
            Subject Details
          </v-stepper-step>
          <v-stepper-content step="4">
            <v-form
              ref="holdingFormHS"
              v-model="isholdingFormValid"
              lazy-validation
            >
              <v-card color="grey lighten-4" class="mb-12" height="auto">
                <v-simple-table class="p-1 m-1">
                  <thead>
                    <tr class="p-0">
                      <th class="text-left" colspan="2">
                        Subject/Course apply for
                      </th>
                      <!-- <th class="text-center">Degree</th>
                      <th class="text-center">Subject/Course apply for</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(items, index) in applyFor" :key="index">
                      <td width="10%">Subject- {{ index + 1 }}</td>
                      <td width="90%">
                        <!-- <v-autocomplete
                          v-model="items.PaperNo"
                          :items="ApplyForItems"
                          filled
                          chips
                          small-chips
                          disabled
                        >
                        </v-autocomplete> -->

                        <v-autocomplete
                          v-model="items.PaperNo"
                          :items="
                            index == 0
                              ? preferenceItems1
                              : index == 1
                              ? preferenceItems2
                              : preferenceItems3
                          "
                          filled
                          chips
                          small-chips
                          :rules="
                            index < 2 && !isFirstOrSecondAutocompleteSelected
                              ? [(v) => !!v || 'This field is required']
                              : []
                          "
                        >
                        </v-autocomplete>
                      </td>
                      <!-- <td>
                        <v-autocomplete
                          v-model="items.Subject"
                          :items="applyForOtherDetailsItem"
                          filled
                          chips
                          small-chips
                        >
                        </v-autocomplete>
                      </td>
                      <td>
                        <v-text-field
                          filled
                          :rules="validationRulesRequired"
                          v-model="items.MarksObtainedTheory"
                        >
                        </v-text-field>
                      </td> -->
                    </tr>

                    <tr>
                      <td class="red--text" colspan="2">
                        * The application will be treated as cancelled if any
                        student select or opt for any subject for which the
                        student is not eligible.
                      </td>
                    </tr>
                    <tr>
                      <td class="red--text" colspan="2">
                        ** Please follow the Course Offered/Notification before
                        selection of Subject/Courses
                      </td>
                    </tr>
                    <tr>
                      <td class="red--text" colspan="2">
                        *** Candidate can choose Library and Information Science
                        with any other choice of Subject/Courses.
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
            </v-form>
            <v-btn color="primary" @click="saveHSDetails"> Next </v-btn>
            <v-btn color="primary" @click="e6 = 3" class="ml-2">
              Previous
            </v-btn>
          </v-stepper-content>

          <v-stepper-step step="5">
            Other Information & Documents Upload(Only JPG image allowed)
          </v-stepper-step>
          <v-stepper-content step="5">
            <v-form
              ref="holdingFormDocument"
              v-model="isholdingFormValid"
              lazy-validation
            >
              <v-card color="grey lighten-4" class="mb-12" height="auto">
                <!-- <v-row>
                  <v-col cols="6">
                    <v-row dense>
                      <v-col cols="10">
                        <v-file-input
                          class="px-4"
                          :placeholder="AgeprofImagepathName"
                          persistent-placeholder
                          v-model="AgeprofImagepath"
                          color="primary"
                          outlined
                          dense
                          label="Age Proof"
                          show-size
                          accept="image/jpg"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="primary"
                              dark
                              label
                              small
                              >{{ text }}</v-chip
                            >
                          </template>
                        </v-file-input>
                      </v-col>
                      <v-col cols="2">
                        <v-icon
                          large
                          color="purple darken-2"
                          @click="AgeprofImagepath_M"
                        >
                          mdi-cloud-upload
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <v-row dense>
                      <v-col cols="10">
                        <v-file-input
                          class="px-2"
                          :placeholder="LastExamName"
                          persistent-placeholder
                          v-model="LastExam"
                          color="primary"
                          outlined
                          dense
                          show-size
                         accept="image/jpg"
                          label="Last Exam Proof"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="primary"
                              dark
                              label
                              small
                              >{{ text }}</v-chip
                            >
                          </template>
                        </v-file-input>
                      </v-col>
                      <v-col cols="2">
                        <v-icon
                          large
                          color="purple darken-2"
                          @click="LastExam_M"
                        >
                          mdi-cloud-upload
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row> -->

                <v-row>
                  <v-col cols="6">
                    <v-row dense>
                      <v-col cols="10">
                        <v-file-input
                          class="px-4 mt-2"
                          :placeholder="SignatureImagepathName"
                          persistent-placeholder
                          v-model="SignatureImagepath"
                          color="primary"
                          outlined
                          dense
                          @change="SignatureImagepath_M"
                          ref="SignatureImagepathRef"
                          label="Signature"
                          show-size
                          accept="image/jpg"
                          :rules="signatureImageRule"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="primary"
                              dark
                              label
                              small
                              >{{ text }}</v-chip
                            >
                          </template>
                          <template #label
                            >Signature<span class="red--text"
                              ><strong> *</strong></span
                            ></template
                          >
                        </v-file-input>
                      </v-col>
                      <v-col cols="2" v-if="false">
                        <v-icon
                          large
                          color="purple darken-2"
                          @click="SignatureImagepath_M"
                        >
                          mdi-cloud-upload
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <v-row dense>
                      <v-col cols="10">
                        <v-file-input
                          class="px-2 mt-2"
                          v-model="photo"
                          :placeholder="photoName"
                          persistent-placeholder
                          color="primary"
                          outlined
                          dense
                          show-size
                          accept="image/jpg"
                          label="Photo"
                          @change="photo_M"
                          ref="photoRef"
                          :rules="photoImageRule"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="primary"
                              dark
                              label
                              small
                              >{{ text }}</v-chip
                            >
                          </template>
                          <template #label
                            >Photo<span class="red--text"
                              ><strong> *</strong></span
                            ></template
                          >
                        </v-file-input>
                      </v-col>
                      <v-col cols="2" v-if="false">
                        <v-icon large color="purple darken-2" @click="photo_M">
                          mdi-cloud-upload
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="6"
                    v-if="
                      SignatureImagepathName != null &&
                      SignatureImagepathName !== ''
                    "
                  >
                    <div class="px-2 d-flex align-center justify-center">
                      <template>
                        <v-img
                          height="200"
                          :lazy-src="imageUrl + SignatureImagepathName"
                          max-width="300"
                          :src="imageUrl + SignatureImagepathName"
                          :aspect-ratio="1"
                        >
                          <template v-slot:placeholder>
                            <div
                              class="d-flex align-center justify-center fill-height"
                            >
                              <v-progress-circular
                                color="grey-lighten-4"
                                indeterminate
                              ></v-progress-circular>
                            </div>
                          </template>
                        </v-img>
                      </template>
                    </div>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="6" v-if="photoName != null && photoName != ''">
                    <div class="px-2 d-flex align-center justify-center">
                      <template>
                        <v-img
                          height="200"
                          :lazy-src="imageUrl + photoName"
                          max-width="300"
                          :src="imageUrl + photoName"
                          :aspect-ratio="1"
                        >
                          <template v-slot:placeholder>
                            <div
                              class="d-flex align-center justify-center fill-height"
                            >
                              <v-progress-circular
                                color="grey-lighten-4"
                                indeterminate
                              ></v-progress-circular>
                            </div>
                          </template>
                        </v-img>
                      </template>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-row dense>
                      <v-col cols="10">
                        <v-file-input
                          class="px-4"
                          v-model="dob"
                          :placeholder="dobName"
                          persistent-placeholder
                          color="primary"
                          outlined
                          dense
                          label="DOB Proof(10th Standard Admit Card/Certificate)"
                          show-size
                          accept="image/jpg"
                          :rules="dobImageRule"
                          @change="dob_M"
                          ref="dobRef"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="primary"
                              dark
                              label
                              small
                              >{{ text }}</v-chip
                            >
                          </template>

                          <template #label
                            >DOB Proof(10th Standard Admit
                            Card/Certificate)<span class="red--text"
                              ><strong> *</strong></span
                            ></template
                          >
                        </v-file-input>
                      </v-col>
                      <v-col cols="2" v-if="false">
                        <v-icon large color="purple darken-2" @click="dob_M">
                          mdi-cloud-upload
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <v-row dense>
                      <v-col cols="10">
                        <v-file-input
                          class="px-2"
                          v-model="XthMarksheet"
                          :placeholder="XthMarksheetName"
                          persistent-placeholder
                          color="primary"
                          outlined
                          dense
                          show-size
                          accept="image/jpg"
                          label="10th Standard Marksheet"
                          :rules="xImageRule"
                          @change="XthMarksheet_M"
                          ref="XthMarksheetRef"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="primary"
                              dark
                              label
                              small
                              >{{ text }}</v-chip
                            >
                          </template>
                          <template #label
                            >10th Standard Marksheet<span class="red--text"
                              ><strong> *</strong></span
                            ></template
                          >
                        </v-file-input>
                      </v-col>
                      <v-col cols="2" v-if="false">
                        <v-icon
                          large
                          color="purple darken-2"
                          @click="XthMarksheet_M"
                        >
                          mdi-cloud-upload
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="6" v-if="dobName != null && dobName != ''">
                    <div class="px-2 d-flex align-center justify-center">
                      <template>
                        <v-img
                          height="200"
                          :lazy-src="imageUrl + dobName"
                          max-width="300"
                          :src="imageUrl + dobName"
                          :aspect-ratio="1"
                        >
                          <template v-slot:placeholder>
                            <div
                              class="d-flex align-center justify-center fill-height"
                            >
                              <v-progress-circular
                                color="grey-lighten-4"
                                indeterminate
                              ></v-progress-circular>
                            </div>
                          </template>
                        </v-img>
                      </template>
                    </div>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col
                    cols="6"
                    v-if="XthMarksheetName != null && XthMarksheetName != ''"
                  >
                    <div class="px-2 d-flex align-center justify-center">
                      <template>
                        <v-img
                          height="200"
                          :lazy-src="imageUrl + XthMarksheetName"
                          max-width="300"
                          :src="imageUrl + XthMarksheetName"
                          :aspect-ratio="1"
                        >
                          <template v-slot:placeholder>
                            <div
                              class="d-flex align-center justify-center fill-height"
                            >
                              <v-progress-circular
                                color="grey-lighten-4"
                                indeterminate
                              ></v-progress-circular>
                            </div>
                          </template>
                        </v-img>
                      </template>
                    </div>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <v-row dense>
                      <v-col cols="10">
                        <v-file-input
                          class="px-4"
                          v-model="XIIthMarksheet"
                          :placeholder="XIIthMarksheetName"
                          persistent-placeholder
                          color="primary"
                          outlined
                          dense
                          label="12th Standard Marksheet"
                          show-size
                          accept="image/jpg"
                          :rules="xiiImageRule"
                          @change="XIIthMarksheet_M"
                          ref="XIIthMarksheetRef"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="primary"
                              dark
                              label
                              small
                              >{{ text }}</v-chip
                            >
                          </template>
                          <template #label
                            >12th Standard Marksheet<span class="red--text"
                              ><strong> *</strong></span
                            ></template
                          >
                        </v-file-input>
                      </v-col>
                      <v-col cols="2" v-if="false">
                        <v-icon
                          large
                          color="purple darken-2"
                          @click="XIIthMarksheet_M"
                        >
                          mdi-cloud-upload
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <v-row dense>
                      <v-col cols="10">
                        <v-file-input
                          class="px-2"
                          v-model="GraduationMarksheet"
                          :placeholder="GraduationMarksheetName"
                          persistent-placeholder
                          color="primary"
                          outlined
                          dense
                          show-size
                          accept="image/jpg"
                          label="Graduation Makrsheet"
                          :rules="gradImageRule"
                          @change="GraduationMarksheet_M"
                          ref="GraduationMarksheetRef"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="primary"
                              dark
                              label
                              small
                              >{{ text }}</v-chip
                            >
                          </template>
                          <template #label
                            >Graduation Makrsheet<span class="red--text"
                              ><strong> *</strong></span
                            ></template
                          >
                        </v-file-input>
                      </v-col>
                      <v-col cols="2" v-if="false">
                        <v-icon
                          large
                          color="purple darken-2"
                          @click="GraduationMarksheet_M"
                        >
                          mdi-cloud-upload
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col
                    cols="6"
                    v-if="
                      XIIthMarksheetName != null && XIIthMarksheetName != ''
                    "
                  >
                    <div class="px-2 d-flex align-center justify-center">
                      <template>
                        <v-img
                          height="200"
                          :lazy-src="imageUrl + XIIthMarksheetName"
                          max-width="300"
                          :src="imageUrl + XIIthMarksheetName"
                          :aspect-ratio="1"
                        >
                          <template v-slot:placeholder>
                            <div
                              class="d-flex align-center justify-center fill-height"
                            >
                              <v-progress-circular
                                color="grey-lighten-4"
                                indeterminate
                              ></v-progress-circular>
                            </div>
                          </template>
                        </v-img>
                      </template>
                    </div>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col
                    cols="6"
                    v-if="
                      GraduationMarksheetName != null &&
                      GraduationMarksheetName != ''
                    "
                  >
                    <div class="px-2 d-flex align-center justify-center">
                      <template>
                        <v-img
                          height="200"
                          :lazy-src="imageUrl + GraduationMarksheetName"
                          max-width="300"
                          :src="imageUrl + GraduationMarksheetName"
                          :aspect-ratio="1"
                        >
                          <template v-slot:placeholder>
                            <div
                              class="d-flex align-center justify-center fill-height"
                            >
                              <v-progress-circular
                                color="grey-lighten-4"
                                indeterminate
                              ></v-progress-circular>
                            </div>
                          </template>
                        </v-img>
                      </template>
                    </div>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <v-row dense v-if="Category != 'Unreserved(UR)'">
                      <v-col cols="10">
                        <v-file-input
                          class="px-4"
                          v-model="CategoryCertificate"
                          :placeholder="CategoryCertificateName"
                          persistent-placeholder
                          color="primary"
                          outlined
                          dense
                          label="Category/Caste Certificate(If any)"
                          show-size
                          accept="image/jpg"
                          @change="CategoryCertificate_M"
                          ref="CategoryCertificateRef"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="primary"
                              dark
                              label
                              small
                              >{{ text }}</v-chip
                            >
                          </template>
                        </v-file-input>
                      </v-col>
                      <v-col cols="2" v-if="false">
                        <v-icon
                          large
                          color="purple darken-2"
                          @click="CategoryCertificate_M"
                        >
                          mdi-cloud-upload
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <v-row dense>
                      <v-col cols="10">
                        <v-file-input
                          class="px-2"
                          v-model="University_Reg_Certificate"
                          :placeholder="University_Reg_CertificateName"
                          persistent-placeholder
                          color="primary"
                          outlined
                          dense
                          show-size
                          accept="image/jpg"
                          label="Valid University Registration Certificate"
                          :rules="universityImageRule"
                          @change="University_Reg_Certificate_M"
                          ref="University_Reg_CertificateRef"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="primary"
                              dark
                              label
                              small
                              >{{ text }}</v-chip
                            >
                          </template>
                          <template #label
                            >Valid University Registration Certificate<span
                              class="red--text"
                              ><strong> *</strong></span
                            ></template
                          >
                        </v-file-input>
                      </v-col>
                      <v-col cols="2" v-if="false">
                        <v-icon
                          large
                          color="purple darken-2"
                          @click="University_Reg_Certificate_M"
                        >
                          mdi-cloud-upload
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col
                    cols="6"
                    v-if="
                      CategoryCertificateName != null &&
                      CategoryCertificateName != '' &&
                      Category != 'Unreserved(UR)'
                    "
                  >
                    <div class="px-2 d-flex align-center justify-center">
                      <template>
                        <v-img
                          height="200"
                          :lazy-src="imageUrl + CategoryCertificateName"
                          max-width="300"
                          :src="imageUrl + CategoryCertificateName"
                          :aspect-ratio="1"
                        >
                          <template v-slot:placeholder>
                            <div
                              class="d-flex align-center justify-center fill-height"
                            >
                              <v-progress-circular
                                color="grey-lighten-4"
                                indeterminate
                              ></v-progress-circular>
                            </div>
                          </template>
                        </v-img>
                      </template>
                    </div>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col
                    cols="6"
                    v-if="
                      University_Reg_CertificateName != null &&
                      University_Reg_CertificateName != ''
                    "
                  >
                    <div class="px-2 d-flex align-center justify-center">
                      <template>
                        <v-img
                          height="200"
                          :lazy-src="imageUrl + University_Reg_CertificateName"
                          max-width="300"
                          :src="imageUrl + University_Reg_CertificateName"
                          :aspect-ratio="1"
                        >
                          <template v-slot:placeholder>
                            <div
                              class="d-flex align-center justify-center fill-height"
                            >
                              <v-progress-circular
                                color="grey-lighten-4"
                                indeterminate
                              ></v-progress-circular>
                            </div>
                          </template>
                        </v-img>
                      </template>
                    </div>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6" v-if="PWD == 'Yes'">
                    <v-row dense>
                      <v-col cols="10">
                        <v-file-input
                          class="px-4"
                          v-model="Physically_Disablied_Certificate"
                          :placeholder="Physically_Disablied_CertificateName"
                          persistent-placeholder
                          color="primary"
                          outlined
                          dense
                          label="PWD/PH Certficate (if any)"
                          show-size
                          accept="image/jpg"
                          @change="Physically_Disablied_Certificate_M"
                          ref="Physically_Disablied_CertificateRef"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="primary"
                              dark
                              label
                              small
                              >{{ text }}</v-chip
                            >
                          </template>
                        </v-file-input>
                      </v-col>
                      <v-col cols="2" v-if="false">
                        <v-icon
                          large
                          color="purple darken-2"
                          @click="Physically_Disablied_Certificate_M"
                        >
                          mdi-cloud-upload
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="6"
                    v-if="EWS == 'Yes' && Category == 'Unreserved(UR)'"
                  >
                    <v-row dense>
                      <v-col cols="10">
                        <v-file-input
                          class="px-4"
                          v-model="EWS_Certificate"
                          :placeholder="EWS_CertificateName"
                          persistent-placeholder
                          color="primary"
                          outlined
                          dense
                          label="EWS Certificate(if any)"
                          show-size
                          accept="image/jpg"
                          @change="EWS_Certificate_M"
                          ref="EWS_CertificateRef"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="primary"
                              dark
                              label
                              small
                              >{{ text }}</v-chip
                            >
                          </template>
                        </v-file-input>
                      </v-col>
                      <v-col cols="2" v-if="false">
                        <v-icon
                          large
                          color="purple darken-2"
                          @click="EWS_Certificate_M"
                        >
                          mdi-cloud-upload
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- <v-col cols="12" md="5" SM="12">
                    <v-autocomplete
                      v-model="Locality"
                      :items="Locality"
                      filled
                      chips
                      small-chips
                      label="Locality"
                      :rules="validationRulesRequired"
                    >
                      <template #label
                        >University<span class="red--text"
                          ><strong> *</strong></span
                        ></template
                      >
                    </v-autocomplete>
                  </v-col> -->
                </v-row>
                <v-row dense>
                  <v-col
                    cols="6"
                    v-if="
                      Physically_Disablied_CertificateName != null &&
                      Physically_Disablied_CertificateName != '' &&
                      PWD == 'Yes'
                    "
                  >
                    <div class="px-2 d-flex align-center justify-center">
                      <template>
                        <v-img
                          height="200"
                          :lazy-src="
                            imageUrl + Physically_Disablied_CertificateName
                          "
                          max-width="300"
                          :src="imageUrl + Physically_Disablied_CertificateName"
                          :aspect-ratio="1"
                        >
                          <template v-slot:placeholder>
                            <div
                              class="d-flex align-center justify-center fill-height"
                            >
                              <v-progress-circular
                                color="grey-lighten-4"
                                indeterminate
                              ></v-progress-circular>
                            </div>
                          </template>
                        </v-img>
                      </template>
                    </div>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col
                    cols="6"
                    v-if="
                      EWS_CertificateName != null &&
                      EWS_CertificateName != '' &&
                      EWS == 'Yes' &&
                      Category == 'Unreserved(UR)'
                    "
                  >
                    <div class="px-2 d-flex align-center justify-center">
                      <template>
                        <v-img
                          height="200"
                          :lazy-src="imageUrl + EWS_CertificateName"
                          max-width="300"
                          :src="imageUrl + EWS_CertificateName"
                          :aspect-ratio="1"
                        >
                          <template v-slot:placeholder>
                            <div
                              class="d-flex align-center justify-center fill-height"
                            >
                              <v-progress-circular
                                color="grey-lighten-4"
                                indeterminate
                              ></v-progress-circular>
                            </div>
                          </template>
                        </v-img>
                      </template>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="mt-2">
                  <v-col
                    cols="6"
                    v-if="ABCId != '' && ABCId != null && ABCId != undefined"
                  >
                    <v-row dense>
                      <v-col cols="10">
                        <v-file-input
                          class="px-4 mt-2"
                          :placeholder="AbcIdImagepathName"
                          persistent-placeholder
                          v-model="AbcIdImagepath"
                          color="primary"
                          outlined
                          dense
                          @change="AbcIdImagepath_M"
                          ref="AbcIdImagepathRef"
                          label="ABC ID"
                          show-size
                          accept="image/jpg"
                          :rules="abcIdImageRule"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="primary"
                              dark
                              label
                              small
                              >{{ text }}</v-chip
                            >
                          </template>
                          <template #label
                            >ABC ID Document<span class="red--text"
                              ><strong> *</strong></span
                            ></template
                          >
                        </v-file-input>
                      </v-col>
                      <v-col cols="2" v-if="false">
                        <v-icon
                          large
                          color="purple darken-2"
                          @click="AbcIdImagepath_M"
                        >
                          mdi-cloud-upload
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="6"
                    v-if="
                      AbcIdImagepathName != null &&
                      AbcIdImagepathName !== '' &&
                      ABCId != '' &&
                      ABCId != null &&
                      ABCId != undefined
                    "
                  >
                    <div class="px-2 d-flex align-center justify-center">
                      <template>
                        <v-img
                          height="200"
                          :lazy-src="imageUrl + AbcIdImagepathName"
                          max-width="300"
                          :src="imageUrl + AbcIdImagepathName"
                          :aspect-ratio="1"
                        >
                          <template v-slot:placeholder>
                            <div
                              class="d-flex align-center justify-center fill-height"
                            >
                              <v-progress-circular
                                color="grey-lighten-4"
                                indeterminate
                              ></v-progress-circular>
                            </div>
                          </template>
                        </v-img>
                      </template>
                    </div>
                  </v-col>
                </v-row>

                <v-row dense class="mx-4">
                  <span class="red--text">
                    * SC/ST/OBC-A/OBC-B and PH/PWD Candidates must have to
                    upload their respective Category/Cast and PH/PWD
                    Certificates. Without such Certificates the Candidates names
                    will be considered only for the Unreserved Merit List
                  </span>
                </v-row>

                <v-divider class="mt-2"></v-divider>
                <v-row>
                  <v-col>
                    <v-checkbox
                      class="mx-4"
                      style="color: #6200ee"
                      disabled
                      v-model="isChekBoxChecked"
                      label="In case the information furnished at any stage of the application and admission process is found to be false or incorrect or if there is any discrepancy or
mismatch between the information furnished by me at the relevant section and the certificates/documents produced at the time of admission/verification, the
provisional admission offered to me shall stand cancelled with immediate effect without assigning any further reason
"
                    >
                      <template slot="label">
                        <div style="color: black">
                          In case the information furnished at any stage of the
                          application and admission process is found to be false
                          or incorrect or if there is any discrepancy or
                          mismatch between the information furnished by me at
                          the relevant section and the certificates/documents
                          produced at the time of admission/verification, the
                          provisional admission offered to me shall stand
                          cancelled with immediate effect without assigning any
                          further reason
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-checkbox
                      class="mx-4"
                      style="color: #6200ee"
                      disabled
                      v-model="isChekBoxChecked1"
                      label="I promise that I will not Indulge in Ragging or any form of violent behaviour. Neither will I tolerate being ragged or subjected to violence. I understand that if I am accused of Ragging the responsibility is on me to prove that I am not guilty. I will not remain a spectator act of Ragging and I will report the matter immediately to my authority.
"
                    >
                      <template slot="label">
                        <div style="color: black">
                          I promise that I will not Indulge in Ragging or any
                          form of violent behaviour. Additionally, I will not
                          tolerate being ragged or subjected to violence. I
                          understand that if I am accused of Ragging the
                          responsibility is on me to prove that I am not guilty.
                          I will not remain a Spectator of act of Ragging and I
                          will report the matter immediately to my authority. As
                          per the UGC Regulations, I shall also submit the anti
                          ragging undertaking/ affidavit in this regard.
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-card>
            </v-form>

            <v-btn color="primary" @click="saveImageDetails"> Submit </v-btn>
            <v-btn color="primary" @click="e6 = 4" class="ml-2">
              Previous
            </v-btn>
          </v-stepper-content>
        </v-stepper>
      </transition>

      <!-- Card End -->
    </v-card>
  </v-container>
</template>
<script>
import { newApplication } from "../newApplication/newApplication";
export default newApplication;
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.9s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.th {
  padding: 0 1px;
}
</style>
