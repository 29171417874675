import { globalMixin } from "../../mixins/globalMixin";
import { ApiEndPoint } from "../../helpers/apiEndPoint";
import { ApiService } from "@/helpers/apiService";
import { Global } from "@/helpers/global";

export const newApplicant = {
  // #region Mixin
  mixins: [globalMixin],
  // #endregion

  // #region Components
  components: {},
  // #endregion

  // #region Data Section
  data() {
    return {
      item: {},
      chkValidation: false,
      mobile: "",
      password: "",
      isLoaderActive: false,
      isholdingFormValid: true,
      isPasswordVisible: false,
      companyName: Global.companyName,

      address: Global.address,
      email: Global.email,
      phone: Global.phone,

      loginLogo: " https://logicnmind.com/gimages/logoi.png",
      courseItems: []
    };
  },
  // #endregion
  //#region - Function Created
  created() {
    // get the vuetify dark theme false
    this.$vuetify.theme.dark = false;
    this.getCourse(1);
  },
  //#endregion
  //#region Mounted
  mounted() { },
  //#endregion

  // #region Method Section
  methods: {
    // #region Validate Login
    newApplicant() {
      this.$router.push({ name: "Login" });
    },
    validation() {
      if (this.item.mobile === this.item.remobile) {
        if (this.item.emailId === this.item.emailIdReEnter) {
          if (this.item.password === this.item.confirmPassword) {
            this.chkValidation = true;
            this.validateLogin();
          } else {
            this.chkValidation = false;
            Global.showErrorAlert(
              true,
              "error",
              "Password & Confirm Password should be same"
            );
          }
        } else {
          this.chkValidation = false;
          Global.showErrorAlert(
            true,
            "error",
            "Email & Confirm Email should be same"
          );
        }
      } else {
        this.chkValidation = false;
        Global.showErrorAlert(
          true,
          "error",
          "Mobile & Confirm Mobile should be same"
        );
      }
    },
    getCourse(typeId) {
      this.isLoaderActive = true;
      ApiService.get(ApiEndPoint.CreateUser.GetMaster, {
        typeId: typeId,
      })
        .then((response) => {
          this.isLoaderActive = false;

          if (response.data.status.isSuccess == "true") {
            this.courseItems = response.data.Data;
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          Global.showErrorAlert(true, "error", "Something went wrong");
        });
    },


    validateLogin() {

      if (this.$refs.holdingForm.validate()) {
        this.isLoaderActive = true;

        ApiService.post(ApiEndPoint.CreateUser.createUser, {
          RollNo: this.item.rollNo,
          UserName: this.item.emailId,
          Password: this.item.password,
          FName: this.item.firstName,
          MName: this.item.middleName,
          LName: this.item.lastName,
          Email: this.item.emailId,
          Mobile: this.item.mobile,
          DepartmentId: 1, //item.DepartmentId,
          DepartmentName: "XYZ",
          UserStatus: 1,
        })
          .then((response) => {
            this.isLoaderActive = false;

            
            if (response.data.status.isSuccess == "true") {
              if (
                response.data.Data != "2" &&
                response.data.Data != "3" &&
                response.data.Data != "0"
              ) {
                Global.showSuccessAlert(
                  true,
                  "success",
                  "Application submitted successfully"
                );
                this.newApplicant();
              } else if (response.data.Data == "2") {
                Global.showErrorAlert(true, "error", "Email already exists");
              } else if (response.data.Data == "3") {
                Global.showErrorAlert(
                  true,
                  "error",
                  "Mobile number  already exists"
                );
              } else if (response.data.Data == "0") {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            
             
            } else if (response.data.result == "error") {
              Global.showErrorAlert(true, "error", response.data.message);
            }
          })
          .catch((error) => {
            this.isLoaderActive = false;
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      }
    },
    // #endregion
  },
  //#endregion
};
