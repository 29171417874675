export const globalMixin = {
  data() {
    return {
      validationRulesRequired: [(v) => !!v || "Field is required"],
      validationRulesMobile: [
        (v) => !!v || "Provide valid Mobile number",
        (v) => (v && v.length >= 10) || "Mobile number must be of 10 digits",
        (v) =>
          /^[6-9]\d{9}$/.test(v) || "Mobile number must start with 6/7/8/9",
      ],
      validationRules_email: [
        (v) => !!v || "Email is required",
        (v) =>
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Provide valid email",
      ],

      validationRules_aadhaar: [
        (v) => !!v || "Aadhaar no  is required",
        (v) =>
          /^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/.test(v) ||
          "Provide valid Aadhaar no ",
      ],

      validationRules_max_25: [
        (v) => !!v || "Filed is required",
        (v) => (v && v.length <= 25) || "Max 25 characters",
      ],

      validationRules_max_75: [
        (v) => !!v || "Filed is required",
        (v) => (v && v.length <= 75) || "Max 75 characters",
      ],
      validationRules_max_25_without_required: [
        (v) => {
          if (v) {
            return v.length <= 25 || "Max 25 characters";
          } else {
            return true;
          }
        },
      ],

      validationRules_optionalemail: [
        (v) => {
          if (v) {
            return (
              /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
              "Provide valid Email"
            );
          } else {
            return true;
          }
        },
      ],
      validationRules_max10: [(v) => (!!v && parseFloat(v) <= 10) || "Max 10"],

      validationRules_optionalAbcdId: [
        (v) => {
          if (v) {
            return v.length === 12 || "ABC ID must be exactly 12 digits";
          } else {
            return true;
          }
        },
      ],
    };
  },
  methods: {
    // #region Accept digit
    acceptDigit(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    // #region noTyping
    noTyping(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    // #endregion

    // #region Accept charcter with space
    acceptCharacterWithSpace(evt) {
      var regex = new RegExp("^[a-zA-z ]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();
        return false;
      }
    },
    // #endregion
    isCharactersWithoutSpace(evt) {
      var regex = new RegExp("^[a-zA-Z]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();
        return false;
      }
    },
  },
};
